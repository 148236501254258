import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { Slider } from 'antd'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { getChainInfo } from 'constants/chainInfo'
import { isIdoSupportedChain, WOD_IDO_ADDRESS } from 'constants/wodContract'
import { useSwitchChain } from 'hooks/useSwitchChain'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getContract } from 'utils'
import { formatUnitsDecimals } from 'utils/commContract'
import { formatEther } from 'utils/ethersUtils'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import IdoJson from '../../abis/ido.json'
import { ReactComponent as SanIcon } from '../../assets/images/san-icon.svg'
import CountDown from './CountDown'

const IdoHomeComm = styled.div`
  width: 100%;
  max-width: 720px;
  margin-right: 160px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xxl}px) {
    max-width: 520px;
    margin-right: 60px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 100%;
    margin-right: 0;
  }
`
const TitAndState = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .tit {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    margin: 0;
    letter-spacing: 0.48px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      font-size: 36px;
    }
  }
  .state-box {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
    color: #000;
    height: 48px;
    span {
      font-size: 20px;
      font-style: italic;
      font-weight: 700;
      line-height: 20px;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      height: 36px;
    }
  }
`
const ProgressBox = styled.div`
  display: flex;
  flex-direction: column;

  .progress-tit {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 26px;
  }
  .progress-item {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 32px;
    border-radius: 100px;
    /* background-color: rgba(255, 255, 255, 0.1); */
    background-color: rgb(26, 26, 26);
    .ant-slider {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      .ant-slider-rail {
        height: 16px;
        border-radius: 100px;
        background: transparent;
      }
      .ant-slider-track {
        height: 16px;
        border-radius: 100px;
        background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
      }
      .ant-slider-handle::before {
        width: 12px;
        height: 12px;
      }
      .ant-slider-handle::after {
        display: none;
        width: 8px;
        height: 8px;
        background-color: #176f69;
        box-shadow: 0 0 0 2px #fff;
      }
    }
  }
  .progress-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 590;
    line-height: 16px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgb(26, 26, 26);
  }
`
const TimerBox = styled.div`
  .release-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 590;
    line-height: 16px;
    margin-bottom: 64px;
    text-align: right;
  }
  & .release-time:first-of-type {
    margin-bottom: 32px;
  }
  .state-time {
    text-align: center;
    font-size: 24px;
    font-weight: 590;
    line-height: 24px;
    margin-bottom: 32px;
  }
  .count-down {
    margin-bottom: 40px;
  }
  .btn-box {
    width: 100%;

    a {
      text-decoration: none;
      padding: 0;
      display: flex;
    }
    span {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
    .view-btn,
    .dis-btn {
      width: 100%;
      height: 60px;
      border-radius: 10px;
      background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .dis-btn {
      background: #454648;
      cursor: no-drop;
      span {
        color: #a2a3a5;
      }
    }
  }
`

const IDO_INTERFACE = new Interface(IdoJson)
export default function IdoHome() {
  const { account, provider, chainId, connector } = useWeb3React()
  const [state, setStageInfo]: any = useState('0')
  const [endTime, setEndTime]: any = useState(0)
  const [pct, setPct]: any = useState(0)
  const [privatebuiedAmount, setPrivatebuiedAmount] = useState(BigNumber.from('0'))
  const [maxprivateAmount, setMaxprivateAmount] = useState(BigNumber.from('0'))
  const [publicbuiedAmount, setPublicbuiedAmount] = useState(BigNumber.from('0'))
  const [maxpublicAmount, setMaxpublicAmount] = useState(BigNumber.from('0'))

  const switchChain = useSwitchChain()
  const supportedChainId = isIdoSupportedChain(chainId)
  const toggleWalletDrawer = useToggleAccountDrawer()

  const stateStr: any = {
    '0': 'upcoming',
    '1': 'private sale',
    '2': 'public sale',
    '3': 'claim',
    '4': 'sale ended',
  }

  // 获取当前阶段
  const getCurrentStageInfo = async () => {
    if (!chainId || !provider) return
    const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
    const state = await ido.getStageInfo()
    console.log('getStageInfo ==>', state)
    const now = parseInt(new Date().getTime() / 1000 + '')
    // const now = await getBlockTimestamp(provider)
    if (now < formatUnitsDecimals(state[0], 0)) {
      // upcoming
      setStageInfo(0)
      setEndTime(state[0].toString())
    } else if (now >= formatUnitsDecimals(state[0], 0) && now < formatUnitsDecimals(state[1], 0)) {
      // private sale
      setStageInfo(1)
      setEndTime(state[1].toString())
    } else if (now >= formatUnitsDecimals(state[1], 0) && now < formatUnitsDecimals(state[2], 0)) {
      // public sale
      setStageInfo(2)
      setEndTime(state[2].toString())
    } else if (now >= formatUnitsDecimals(state[2], 0) && now < formatUnitsDecimals(state[3], 0)) {
      // sale ended
      setStageInfo(4)
      setEndTime(state[3].toString())
    } else if (now >= formatUnitsDecimals(state[3], 0)) {
      // claim
      setStageInfo(3)
      setEndTime(0)
    }
    // setStageInfo(state[0].toString())
    // setEndTime(state[1].toString())
    // console.log('state ==>', state[0].toString())
    // console.log('endTime ==>', state[1].toString())
  }
  const onChangePct = (value: any) => {
    // setPct(value)
  }

  const getPrivateInfo = async () => {
    if (!chainId || !provider) return
    const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
    const privatebuied: BigNumber = await ido.privatebuied()
    const maxprivate: BigNumber = await ido.maxprivateCount()
    setPrivatebuiedAmount(privatebuied.mul(100))

    setMaxprivateAmount(maxprivate.mul(100))
    console.log('privatebuied ==>', privatebuied.mul(100), maxprivate.mul(100))
  }

  const getpublicInfo = async () => {
    if (!chainId || !provider) return
    const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
    const publicbuied: BigNumber = await ido.totalBuiedAmount()
    const maxpublic: BigNumber = await ido.maxAmount()
    // tips
    setPublicbuiedAmount(publicbuied)
    // setPublicbuiedAmount(BigNumber.from('0'))

    setMaxpublicAmount(maxpublic)
    console.log('publicbuied ==>', publicbuied.toString(), maxpublic.toString())
  }

  const comptPct = (saled: any, max: any) => {
    // tips
    const saledNum = saled
    // const saledNum = formatEther(0)

    const maxNum = max
    const pctBigNum = (saledNum / maxNum) * 100
    setPct(pctBigNum.toFixed(2))
    console.log('pctBigNum ==>', saledNum, maxNum, pctBigNum.toFixed(2))
  }

  useMemo(() => {
    if (state == 0 || state == 1) {
      // 私募
      comptPct(formatUnitsDecimals(privatebuiedAmount, 0), formatUnitsDecimals(maxprivateAmount, 0))
    } else {
      // 公募
      comptPct(formatUnitsDecimals(publicbuiedAmount), formatUnitsDecimals(maxpublicAmount))
    }
  }, [privatebuiedAmount, maxprivateAmount, publicbuiedAmount, maxpublicAmount])

  useEffect(() => {
    getPrivateInfo()
    getpublicInfo()
    getCurrentStageInfo()
  }, [provider, account, chainId])

  return (
    <IdoHomeComm>
      <TitAndState>
        <h1 className="tit">EON</h1>
        <div className="state-box">
          <SanIcon></SanIcon>
          <span>{stateStr[state]}</span>
        </div>
      </TitAndState>
      <ProgressBox>
        <div className="progress-tit">
          <span>Progress ({pct}%)</span>
        </div>
        <div className="progress-item">
          <Slider defaultValue={pct} value={pct} onChange={(value) => onChangePct(value)} tooltip={{ open: false }} />
        </div>
        <div className="progress-amount">
          {state == 1 ? (
            <>
              <span>{Number(formatUnitsDecimals(privatebuiedAmount.toString(), 0)).toLocaleString()} USDT</span>
              <span>{Number(formatUnitsDecimals(maxprivateAmount.toString(), 0)).toLocaleString()} USDT</span>
            </>
          ) : (
            <>
              {/* <span>{Number(formatEther(publicbuiedAmount.toString())).toLocaleString()} USDT</span> */}
              <span>{Number(formatEther(maxpublicAmount.toString())).toLocaleString()} USDT</span>
              <span>{Number(formatEther(maxpublicAmount.toString())).toLocaleString()} USDT</span>
              {/* <span>{Number(200000).toLocaleString()} USDT</span> */}
            </>
          )}
        </div>
      </ProgressBox>
      <TimerBox>
        {/* {state != 3 && ( */}
        <>
          <div className="release-time">
            <span>Release time:</span>
            <span>6.20 7:00(UTC)</span>
            {/* <span>{endTime && endTime != 0 && ymdhm(endTime.toString() * 1000)} (UTC)</span> */}
          </div>
          <div className="release-time">
            <span>Unlock:</span>
            <span>Unlock 50% at TGE, 6 months linear release the remaining</span>
          </div>
        </>
        {/* )} */}
        <div className="state-time">
          {state == 0 && <span>Starts in</span>}
          {state == 1 && <span>Private sale ends in</span>}
          {state == 2 && <span>Public sale ends in</span>}
          {state == 4 && <span>Eon can be claimed in</span>}
        </div>
        {state != 3 && (
          <div className="count-down">
            <CountDown endT={endTime.toString()} init={() => getCurrentStageInfo()}></CountDown>
          </div>
        )}
        <div className="btn-box">
          {state != 0 ? (
            <Link to="/launchpad/view">
              <div className="view-btn">
                <span>view</span>
              </div>
            </Link>
          ) : !account ? (
            <div className="view-btn" onClick={toggleWalletDrawer}>
              <span>Please connect to your wallet</span>
            </div>
          ) : chainId && chainId !== supportedChainId ? (
            <div
              className="view-btn"
              onClick={async () => {
                try {
                  await switchChain(connector, 787878 as any)
                } catch (error) {
                  if (didUserReject(error)) {
                    // Ignore error, which keeps the user on the previous chain.
                  } else {
                    // TODO(WEB-3306): This UX could be improved to show an error state.
                    throw error
                  }
                }
              }}
            >
              <span>Connect to {getChainInfo(787878)?.label}</span>
            </div>
          ) : (
            <div className="view-btn dis-btn">
              <span>view</span>
            </div>
          )}
        </div>
      </TimerBox>
    </IdoHomeComm>
  )
}
