import { useLocation } from 'react-router-dom'

export function useIsFarmPage() {
  const { pathname } = useLocation()
  return pathname.startsWith('/farm')
  // pathname.startsWith('/pool') ||
  // pathname.startsWith('/add') ||
  // pathname.startsWith('/remove') ||
  // pathname.startsWith('/increase')
}
