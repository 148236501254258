import { Interface } from '@ethersproject/abi'
import { getCreate2Address } from '@ethersproject/address'
import { keccak256, pack } from '@ethersproject/solidity'
import { Token } from '@uniswap/sdk-core'
import IUniswapV2Factory from '@uniswap/v2-core/build/IUniswapV2Factory.json'
import { WOD_INIT_CODE_HASH } from 'constants/wodContract'

const V2_FACTORY = new Interface(IUniswapV2Factory.abi)

// export async function useWodComputePairAddress() {
//   const { provider, chainId, account } = useWeb3React()
//   if (!provider || !chainId) return null
//   const contract = getContract(WOD_V2_FACTORY_ADDRESSES[chainId], V2_FACTORY, provider)
//   const pairAddr = await contract.pairFor(store.getState().token.token0.address, store.getState().token.token1.address)
//   return pairAddr
// }

export function getPairAddress(chainId: number, factoryAddress: string, tokenA: Token, tokenB: Token) {
  // 获取两个 ERC20 代币的排序后的地址
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
  const INIT_CODE_HASH = WOD_INIT_CODE_HASH[chainId]
  // 计算交易对地址
  const pair = getCreate2Address(
    factoryAddress,
    keccak256(['bytes'], [pack(['address', 'address'], [token0.address, token1.address])]),
    INIT_CODE_HASH
  )
  return pair
}
