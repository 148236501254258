import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { useWeb3React } from '@web3-react/core'
import ERC20_ABI from 'abis/erc20.json'
import { Select, Slider, Tooltip } from 'antd'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { loadingOpacityMixin } from 'components/Loader/styled'
import { getChainInfo } from 'constants/chainInfo'
import {
  isIdoSupportedChain,
  WOD_ETH_TOKEN_ADDRESS,
  WOD_IDO_ADDRESS,
  WOD_IDO_TOKEN_ADDRESS,
} from 'constants/wodContract'
import { useSwitchChain } from 'hooks/useSwitchChain'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { getContract } from 'utils'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { decimalsToBig, formatUnitsDecimals } from 'utils/commContract'
import { formatEther } from 'utils/ethersUtils'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import IdoJson from '../../abis/ido.json'
import eonClaimImge from '../../assets/images/eon-claim.png'
import eonIdo from '../../assets/images/eon-ido-icon.png'
import ETHIcon from '../../assets/images/ethereum-logo.png'
import USDTIcon from '../../assets/images/usdt.png'
import { ReactComponent as DcI } from '../../assets/svg/discord-icon.svg'
import { ReactComponent as SelectI } from '../../assets/svg/select-down.svg'
import { ReactComponent as TgI } from '../../assets/svg/tg-icon.svg'
import { ReactComponent as TipI } from '../../assets/svg/tips-icon.svg'
import { ReactComponent as TwiI } from '../../assets/svg/twitter-icon.svg'
import { ReactComponent as WebI } from '../../assets/svg/web-icon.svg'
import { Input as NumericalInput } from '../../components/NumericalInput'
import BuySuccessModal from './BuySuccessModal'
import ComeSoonModal from './ComeSoonModal'
import CountDown from './CountDown'

const { Option } = Select

const LaunchpadCardComp = styled.div`
  width: 100%;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);
  padding: 40px;
  position: relative;
  .state-box {
    position: absolute;
    top: -1px;
    right: -1px;
    border-radius: 0px 24px;
    /* border: 1px solid rgba(255, 255, 255, 0.6); */
    /* box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6); */
    background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
    padding: 10px 24px 14px;
    span {
      display: flex;
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      line-height: 20px;
      color: #000;
    }
  }
  .top-eon {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .eon-logo {
      width: 100px;
      img {
        width: 100%;
      }
    }
    .eon-links {
      display: flex;
      flex-direction: column;
      gap: 12px;
      > span {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 20px 10px;
  }
`
const IDO_INTERFACE = new Interface(IdoJson)

const VedioList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  .link-item {
    display: flex;
    width: 28px;
    height: 28px;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
const EonVedioList = () => {
  const list = [
    {
      name: 'web',
      icon: <WebI></WebI>,
      link: 'https://eonchain.xyz/',
    },
    {
      name: 'twi',
      icon: <TwiI></TwiI>,
      link: 'https://twitter.com/Eonchainz',
    },
    {
      name: 'dc',
      icon: <DcI></DcI>,
      link: 'https://discord.gg/Wnma9vgZBh',
    },
    {
      name: 'tg',
      icon: <TgI></TgI>,
      link: 'https://t.me/eonchain',
    },
  ]
  const toLink = (link: string) => {
    window.open(link)
  }
  return (
    <VedioList>
      {list.map((item, index) => {
        return (
          <div className="link-item" key={index} onClick={() => toLink(item.link)}>
            {item.icon}
          </div>
        )
      })}
    </VedioList>
  )
}

const TimerBox = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  .state-time {
    text-align: center;
    font-size: 24px;
    font-weight: 590;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .count-down {
    margin-bottom: 24px;
  }
  .btn-box {
    width: 100%;

    a {
      text-decoration: none;
      padding: 0;
      display: flex;
    }
    span {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
    .view-btn {
      width: 100%;
      height: 60px;
      border-radius: 10px;
      background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .eon-claim {
    padding: 34px 63px;
    display: inline-block;
    margin: auto;
    margin-bottom: 70px;
    background: url(${eonClaimImge}) no-repeat center;
    background-size: 100% 100%;
    span {
      font-size: 60px;
      font-weight: 700;
      line-height: 60px;
      background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .claim-box {
    .btn-default {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: var(--2, linear-gradient(90deg, #00f954 0%, #ede051 100%));
      cursor: pointer;
      span {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
      }
      @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
        height: 48px;
        span {
          font-size: 14px;
        }
      }
    }
    .disabled {
      background: #454648;
      cursor: no-drop;
      span {
        color: #a2a3a5;
      }
    }
  }
`
const ProgressBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .progress-tit {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .progress-item {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 16px;
    /* background-color: rgb(26, 26, 26); */
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.32) inset;
    position: relative;
    .ant-slider {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 2px;
      z-index: 1;
      .ant-slider-rail {
        height: 16px;
        border-radius: 100px;
        background: transparent;
      }
      .ant-slider-track {
        height: 16px;
        border-radius: 100px;
        background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
      }
      .ant-slider-handle::before {
        width: 12px;
        height: 12px;
      }
      .ant-slider-handle::after {
        display: none;
        width: 8px;
        height: 8px;
        background-color: #176f69;
        box-shadow: 0 0 0 2px #fff;
      }
    }
    & .ant-slider:nth-of-type(2) {
      z-index: 0;
      .ant-slider-track {
        background: rgba(217, 217, 217, 0.16);
      }
    }
    & .ant-slider:nth-of-type(3) {
      .ant-slider-track {
        background: #454648;
        /* margin-left: -1px; */
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        /* &::after {
          display: block;
          content: '';
          position: absolute;
          right: 0px;
          top: -2px;
          width: 4px;
          height: 22px;
          background-color: #fff;
          border-right: 2px solid #000;
        } */
      }
      .ant-slider-handle::after {
        display: block;
        width: 4px;
        height: 22px;
        background-color: #fff;
        border-radius: 0;
        border: none;
        right: 0px;
        top: -4px;
        border-right: 2px solid #000;
        box-shadow: none;
      }
    }
  }

  .progress-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 590;
    line-height: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    & span:nth-of-type(2) {
      position: absolute;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      font-size: 10px;
      & span:nth-of-type(2) {
        position: absolute;
        /* margin-left: 50px; */
      }
    }
  }
`
const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown {
    padding: 0;
    background-color: #302F28;
    .ant-select-item {
      padding: 10px;
      border-radius: 0;
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
          border-radius: 50%;
        }
        span {
          color: rgba(255, 255, 255, 0.80);
          /* font-family: "PingFang SC"; */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 100% */
        }
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      /* background-color: #302F28; */
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          color: rgba(255, 255, 255, 0.80);
          /* font-family: "PingFang SC"; */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 100% */
        }
      }
    }
  }
`
const AmountBox = styled.div`
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  margin-bottom: 24px;
  /* padding-bottom: 24px; */
  .private-amount,
  .public-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .amount-tips {
      display: flex;
      align-items: center;
      gap: 16px;
      svg:hover {
        g {
          path {
            fill: #12f754;
          }
        }
      }
      span {
        display: flex;
        font-size: 16px;
        font-weight: 590;
        line-height: 16px;
      }
    }
    .amount-ut {
      span {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        background: linear-gradient(90deg, #00f954 0%, #ede051 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .public-amount {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 16px 24px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.1);
    .input-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        font-size: 24px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .max {
        display: flex;
        cursor: pointer;
        color: #3fdd78;
        font-size: 24px;
        font-weight: 500;
        margin-left: 20px;
        line-height: 24px;
      }
    }
  }
  .public-inputbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title-amount {
      width: 100%;
      span {
        color: #fff;
        /* font-family: 'SF Pro'; */
        font-size: 16px;
        font-style: normal;
        font-weight: 590;
        line-height: 16px; /* 100% */
      }
    }
    .public-input {
      display: flex;
      width: 100%;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.6);
      .token-input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .select-box {
          width: 112px;
          min-width: 112px;
          height: 32px;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 8px 4px 4px;
          border-radius: 100px;
          background: #302f28;
          position: relative;
          img {
            border-radius: 50%;
            width: 24px;
            object-fit: cover;
            position: absolute;
            left: 4px;
            top: 4px;
          }
          .ant-select {
            width: 100%;
            .ant-select-selector {
              padding: 0;
              padding-left: 32px;
              border: none;
              background: transparent;
              box-shadow: none;
              .ant-select-selection-item {
                padding-right: 0;
                color: rgba(255, 255, 255, 0.8);
                /* font-family: 'PingFang SC'; */
                font-size: 16px;
                font-weight: 600;
                line-height: 16px; /* 100% */
                img {
                  display: none;
                }
              }
            }
            .ant-select-arrow {
              right: 0;
            }
          }
        }
        .input-box {
          width: 100%;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 16px;
          input {
            text-align: right;
            /* font-family: 'SF Pro'; */
            font-size: 32px;
            font-weight: 510;
            line-height: 32px; /* 100% */
          }
          span {
            color: #fff;
            /* font-family: 'SF Pro'; */
            font-size: 32px;
            font-weight: 510;
            line-height: 32px; /* 100% */
          }
        }
      }
      .balance-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: rgba(255, 255, 255, 0.6);
          /* font-family: 'SF Pro'; */
          font-size: 14px;
          line-height: 14px; /* 100% */
        }
      }
    }
  }
`
const ClaimedBox = styled(AmountBox)``

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  max-height: 24px;
  line-height: 24px;
  opacity: 1;
`
const BtnBox = styled.div`
  .btn-default {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: var(--2, linear-gradient(90deg, #00f954 0%, #ede051 100%));
    cursor: pointer;
    span {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      height: 48px;
      span {
        font-size: 14px;
      }
    }
  }
  .disabled {
    background: #454648;
    cursor: no-drop;
    span {
      color: #a2a3a5;
    }
  }
`
const LoadingTrans = styled.div`
  position: relative;
  text-align: left;
  &::after {
    display: block;
    content: '. . .';
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    transform: translateX(120%);
    animation: pendingKey 1s infinite linear;
  }
  @keyframes pendingKey {
    0% {
      content: '.';
    }
    50% {
      content: '. .';
    }
    100% {
      content: '. . .';
    }
  }
`

const PublicTitpsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .min-box,
  .max-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`
const PublicTitps = ({ min, max }: { min: BigNumber; max: BigNumber }) => {
  return (
    <PublicTitpsStyle>
      <div className="min-box">
        <span>Minimum Buy</span>
        <span>{formatEther(min.toString())} USDT</span>
      </div>
      <div className="max-box">
        <span>Maximum Buy</span>
        <span>{formatEther(max.toString())} USDT</span>
      </div>
    </PublicTitpsStyle>
  )
}

export default function LaunchpadCard() {
  const { account, provider, chainId, connector } = useWeb3React()
  const [state, setStageInfo]: any = useState('0')
  const [endTime, setEndTime]: any = useState(0)
  const [pct, setPct]: any = useState(0)
  const [sliderPostion, setSliderPostion]: any = useState(0)
  const [privatebuiedAmount, setPrivatebuiedAmount] = useState(BigNumber.from('0'))
  const [maxprivateAmount, setMaxprivateAmount] = useState(BigNumber.from('0'))
  const [publicbuiedAmount, setPublicbuiedAmount] = useState(BigNumber.from('0'))
  const [maxpublicAmount, setMaxpublicAmount] = useState(BigNumber.from('0'))
  const [isShowSlider, setIsShowSlider] = useState(false)
  const [usdtBalance, setUsdtBalance] = useState(BigNumber.from('0'))
  const [ethBalance, setEthBalance] = useState(BigNumber.from('0'))
  const [buyLoading, setBuyLoading] = useState(false)
  const [claimLoading, setClaimLoading] = useState(false)
  const [approveLoading, setApproveLoding] = useState(false)
  const [approveToken, setApproveToken] = useState(false)
  const [eonPrivateAmount, setEonPrivateAmount] = useState(BigNumber.from('0'))
  const [eonPublicAmount, setEonPublicAmount] = useState(BigNumber.from('0'))
  const [withdrawedAmount, setWithdrawedAmount] = useState(BigNumber.from('0'))
  const [showBuy, setShowBuy] = useState(false)
  const [showCommSoon, setShowCommSoon] = useState(false)
  const [publicAmount, setPublicAmount] = useState('')
  const [white, setWhite] = useState(false)
  const [publicLimitMin, setPublicLimitMin] = useState(BigNumber.from('0'))
  const [publicLimitMax, setPublicLimitMax] = useState(BigNumber.from('0'))

  // tips: config value
  const privateAmount = decimalsToBig('100') // state == 1 >> private usdt amount
  const [privatePrice, setPrivatePrice] = useState(decimalsToBig(1 / 24))
  const [publicPrice, setPublicPrice] = useState(decimalsToBig(1 / 20))

  const [claimEonAmount, setClaimEonAmount] = useState(BigNumber.from('0'))
  const inputPublicInputRef = useRef<HTMLInputElement>(null)
  const modalHeight: number | undefined = 0

  const toggleWalletDrawer = useToggleAccountDrawer()
  const switchChain = useSwitchChain()
  const supportedChainId = isIdoSupportedChain(chainId)

  const stateStr: any = {
    '0': 'upcoming',
    '1': 'private sale',
    '2': 'public sale',
    '3': 'claim',
    '4': 'sale ended',
  }
  const handleDismissModal = () => {
    setShowBuy(false)
    setShowCommSoon(false)
  }

  const onUserInput = useCallback(
    (value: string) => {
      setPublicAmount(value)
      comptEon()
      if (!value || value == '0') return
      verifyApprove()
    },
    [publicAmount]
  )

  // 获取当前阶段
  const getCurrentStageInfo = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const state = await ido.getStageInfo()
      const now = parseInt(new Date().getTime() / 1000 + '')
      // const now = await getBlockTimestamp(provider)
      if (now < formatUnitsDecimals(state[0], 0)) {
        // upcoming
        setStageInfo(0)
        setEndTime(state[0].toString())
      } else if (now >= formatUnitsDecimals(state[0], 0) && now < formatUnitsDecimals(state[1], 0)) {
        // private sale
        setStageInfo(1)
        setEndTime(state[1].toString())
      } else if (now >= formatUnitsDecimals(state[1], 0) && now < formatUnitsDecimals(state[2], 0)) {
        // public sale
        setStageInfo(2)
        setEndTime(state[2].toString())
      } else if (now >= formatUnitsDecimals(state[2], 0) && now < formatUnitsDecimals(state[3], 0)) {
        // sale ended
        setStageInfo(4)
        setEndTime(state[3].toString())
      } else if (now >= formatUnitsDecimals(state[3], 0)) {
        // claim
        setStageInfo(3)
        setEndTime(0)
      }
      console.log('state ==>', state)
      // console.log('endTime ==>', state[1].toString(), WOD_IDO_ADDRESS[chainId])
    } catch (error) {
      setStageInfo(0)
      setEndTime(0)
    }
  }
  const onChangePct = (value: any) => {
    // setPct(value)
  }

  const maxUsdt = () => {
    if (usdtBalance.isZero()) return
    if (eonPublicAmount.isZero()) {
      if (decimalsToBig(usdtBalance).gt(publicLimitMax)) {
        return setPublicAmount(formatEther(publicLimitMax.toString()) + '')
      } else {
        return setPublicAmount(formatEther(usdtBalance.toString()) + '')
      }
    } else {
      const uPrice = decimalsToBig(decimalsToBig('1').div(publicPrice))
      const maxEdUsdt = decimalsToBig(eonPublicAmount.div(uPrice))
      const balUsdt = publicLimitMax.sub(maxEdUsdt)
      if (decimalsToBig(usdtBalance).gt(balUsdt)) {
        return setPublicAmount(formatEther(balUsdt.toString()) + '')
      } else {
        return setPublicAmount(formatEther(usdtBalance.toString()) + '')
      }
    }
  }

  const getPrivateInfo = async () => {
    if (!chainId || !provider) return
    const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
    const privatebuied: BigNumber = await ido.privatebuied()
    const maxprivate: BigNumber = await ido.maxprivateCount()
    setPrivatebuiedAmount(decimalsToBig(privatebuied.mul(100)))

    setMaxprivateAmount(decimalsToBig(maxprivate.mul(100)))
    console.log('privatebuied ==>', privatebuied.mul(100), maxprivate.mul(100))
  }

  const getpublicInfo = async () => {
    if (!chainId || !provider) return
    const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
    const publicbuied: BigNumber = await ido.totalBuiedAmount()
    const maxpublic: BigNumber = await ido.maxAmount()
    setPublicbuiedAmount(publicbuied)
    // tips
    // setPublicbuiedAmount(BigNumber.from('0'))

    setMaxpublicAmount(maxpublic)
    console.log('publicbuied ==>', publicbuied.toString(), maxpublic.toString())
  }

  const comptPct = (saled: BigNumber, max: BigNumber) => {
    const saledNum = formatEther(saled)
    const maxNum = formatEther(max)
    const pctBigNum = (saledNum / maxNum) * 100

    const privateNum = formatEther(maxprivateAmount)
    const publicNum = formatEther(maxpublicAmount)
    const pctSlider = (privateNum / publicNum) * 100
    setPct(pctBigNum.toFixed(2))
    setSliderPostion(pctSlider.toFixed(2))
    // setPct(20)
    setIsShowSlider(publicbuiedAmount.gt(maxprivateAmount))
    console.log('pctSlider ==>', pctSlider.toFixed(2))
    console.log('pctBigNum ==>', saledNum, maxNum, pctBigNum.toFixed(2), pctBigNum)
  }

  useMemo(() => {
    if (state == 0 || state == 1) {
      // 私募
      comptPct(privatebuiedAmount, maxpublicAmount)
    } else {
      // 公募
      comptPct(publicbuiedAmount, maxpublicAmount)
    }
  }, [privatebuiedAmount, maxprivateAmount, publicbuiedAmount, maxpublicAmount])

  const getInWhiteList = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const isWhite = await ido.isInWhiteList(account)
      setWhite(isWhite)
      console.log('isWhite ==>', isWhite)
    } catch (error) {
      console.log(error)
    }
  }

  const [pendingEon, setPendingEon] = useState(BigNumber.from('0'))
  const getPendingEon = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const eon = await ido.getPendingEon(account)
      setPendingEon(eon)
      console.log('pendingEon ==>', eon.toString())
    } catch (error) {
      console.log(error)
    }
  }

  const getUsdtBalance = async () => {
    try {
      if (!provider || !account || !chainId) return

      const tokenContract = getContract(WOD_IDO_TOKEN_ADDRESS[chainId], ERC20_ABI, provider)
      const bal = await tokenContract.balanceOf(account)
      setUsdtBalance(bal)
      console.log('tokenBalance usdt ==>', bal)
    } catch (error) {
      console.log(error)
    }
  }
  const getEthBalance = async () => {
    try {
      if (!provider || !account || !chainId) return

      const tokenContract = getContract(WOD_ETH_TOKEN_ADDRESS[chainId], ERC20_ABI, provider)
      const bal = await tokenContract.balanceOf(account)
      setEthBalance(bal)
      console.log('tokenBalance eth ==>', bal)
    } catch (error) {
      console.log(error)
    }
  }
  const [ethPrice, setEthprice] = useState(BigNumber.from('0'))
  const getEthprice = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const ethprice = await ido.ethprice()
      setEthprice(ethprice)
      console.log('ethprice ==>', ethprice)
    } catch (error) {
      console.log(error)
    }
  }

  const getEonPrivate = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const EonPrivate: BigNumber = await ido.EonPrivate(account)
      setEonPrivateAmount(EonPrivate)
      console.log('EonPrivate ==>', EonPrivate.toString())
    } catch (error) {
      console.log(error)
    }
  }

  const getEonPublic = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const EonPublic: BigNumber = await ido.EonPublic(account)
      setEonPublicAmount(EonPublic)
      console.log('EonPublic ==>', EonPublic.toString())
    } catch (error) {
      console.log(error)
    }
  }

  const getWithdrawed = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const withdrawed = await ido.withdrawed(account)
      setWithdrawedAmount(withdrawed)
      console.log('withdrawed ==>', withdrawed)
    } catch (error) {
      console.log(error)
    }
  }
  // useEffect(() => {
  //   const totalClaim = eonPrivateAmount.add(eonPublicAmount).sub(withdrawedAmount)
  //   setClaimEonAmount(totalClaim)
  // }, [eonPrivateAmount, eonPublicAmount, withdrawedAmount])

  const verifyApprove = async () => {
    try {
      if (!provider || !account || !chainId || approveLoading) return
      // if (token.toLowerCase() === WRAPPED_NATIVE_CURRENCY[chainId]?.address.toLowerCase()) return
      let isApprove = false
      const addr = tokenType === 'usdt' ? WOD_IDO_TOKEN_ADDRESS[chainId] : WOD_ETH_TOKEN_ADDRESS[chainId]
      const tokenAContract = getContract(addr, ERC20_ABI, provider)
      const allowance: BigNumber = await tokenAContract.allowance(account, WOD_IDO_ADDRESS[chainId])
      if (state == 1) {
        // private
        console.log('allowance private==>', allowance.toString(), allowance.lt(privateAmount), privateAmount.toString())
        if (allowance.lt(privateAmount)) {
          isApprove = false
          setApproveToken(false)
        } else {
          isApprove = true
          setApproveToken(true)
        }
      } else if (state == 2) {
        console.log(
          'allowance public==>',
          allowance.toString(),
          allowance.lt(decimalsToBig(publicAmount)),
          publicAmount
        )
        // public
        if (allowance.lt(decimalsToBig(publicAmount))) {
          isApprove = false
          setApproveToken(false)
        } else {
          isApprove = true
          setApproveToken(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const initVerify = async () => {
    await verifyApprove()
  }

  const approve = async (token: string) => {
    console.log('approve')
    try {
      setApproveLoding(true)
      if (!token || !provider || !account || !chainId) return
      const tokenContract = getContract(token, ERC20_ABI, provider, account)

      const estimatedGas = await tokenContract.estimateGas
        .approve(WOD_IDO_ADDRESS[chainId], MaxUint256)
        .catch((error) => {
          console.log(error)
          return tokenContract.estimateGas.approve(WOD_IDO_ADDRESS[chainId], MaxUint256)
        })
      tokenContract
        .approve(WOD_IDO_ADDRESS[chainId], MaxUint256, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        .then((response: any) => {
          console.log('approve hax ==>', response)
          provider.once(response.hash, async (tx) => {
            setApproveLoding(false)
            initVerify()
          })
        })
        .catch((error: Error) => {
          setApproveLoding(false)
          throw error
        })
    } catch (error) {
      setApproveLoding(false)
    }
  }

  const getPublicLimit = async () => {
    try {
      if (!chainId || !provider) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider)
      const publicLimitInfo = await ido.getPublicLimit()
      setPublicLimitMin(publicLimitInfo[0])
      setPublicLimitMax(publicLimitInfo[1])
      console.log('publicLimitInfo ==>', publicLimitInfo)
    } catch (error) {
      console.log(error)
    }
  }

  const privateAndPublicBuy = async () => {
    try {
      setBuyLoading(true)
      if (!chainId || !provider || !account) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider, account)
      let method = ''
      let params: any[] = []
      let value: any = null
      console.log(params)
      if (state == 1) {
        // wod token
        method = 'BuyPrivate'
        params = []
        value = null
      } else {
        // token token
        method = tokenType === 'usdt' ? 'BuyPublic' : 'BuyPublicByEth'
        params = [decimalsToBig(publicAmount)]
        value = null
      }
      const methodFuc = ido[method]

      const estimate = ido.estimateGas[method]
      await estimate(...params, value ? { value } : {})
        .then((estimatedGasLimit) =>
          methodFuc(...params, {
            ...(value ? { value } : {}),
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response: any) => {
            // console.log(response)
            provider.once(response.hash, async (tx: any) => {
              setBuyLoading(false)
              setShowBuy(true)
            })
          })
        )
        .catch((error) => {
          setBuyLoading(false)
          console.log(error.reason)
        })
    } catch (error) {
      setBuyLoading(false)
      console.log(error)
    }
  }
  const claim = async () => {
    try {
      setClaimLoading(true)
      if (!chainId || !provider || !account) return
      const ido = getContract(WOD_IDO_ADDRESS[chainId], IDO_INTERFACE, provider, account)
      const method = 'Claim'
      const params: any[] = []
      const value: any = null
      console.log(params)

      const methodFuc = ido[method]

      const estimate = ido.estimateGas[method]
      await estimate(...params, value ? { value } : {})
        .then((estimatedGasLimit) =>
          methodFuc(...params, {
            ...(value ? { value } : {}),
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response: any) => {
            // console.log(response)
            provider.once(response.hash, async (tx: any) => {
              console.log(tx)
              // 0x000000000000000000000000000000000000000000000000d02ab486cedc0000
              // const nums = tx?.logs[0]?.data || pendingEon._hex
              // const nums = pendingEon._hex
              // const amount = parseInt(nums, 16) + ''
              // setClaimEonAmount(decimalsToBig(formatEther(amount)))
              setClaimEonAmount(pendingEon)
              setClaimLoading(false)
              setShowBuy(true)
            })
          })
        )
        .catch((error) => {
          setClaimLoading(false)
          console.log(error.reason)
        })
    } catch (error) {
      setClaimLoading(false)
      console.log(error)
    }
  }

  const [tokenType, setTokenType] = useState('usdt')

  const comptEon = () => {
    const MULTIPLIER = BigNumber.from(10).pow(18)
    // public
    if (tokenType === 'usdt') {
      return decimalsToBig(publicAmount).mul(MULTIPLIER).div(publicPrice)
    } else {
      const toU = formatEther(decimalsToBig(publicAmount).mul(ethPrice))
      return decimalsToBig(toU).mul(MULTIPLIER).div(publicPrice)
    }
  }

  const cmputEONClaim = () => {
    const totalClaim = eonPrivateAmount.add(eonPublicAmount).sub(withdrawedAmount)
    if (state == 1) {
      // 私募
      return eonPrivateAmount
    } else if (state == 2) {
      return comptEon()
    } else if (state == 3) {
      return claimEonAmount
    }
    return BigNumber.from('0')
  }

  const cmputUsdtClaim = () => {
    const totalClaim = eonPrivateAmount.add(eonPublicAmount).sub(withdrawedAmount)

    if (state == 1) {
      // 私募
      const uPrice = decimalsToBig(decimalsToBig('1').div(privatePrice))
      return decimalsToBig(eonPrivateAmount.div(uPrice))
    } else if (state == 2) {
      return decimalsToBig(publicAmount)
    }
    // else if (state == 3) {
    //   // claim
    //   const privateUsdt = eonPrivateAmount.div(privatePrice)
    // }
    return BigNumber.from('0')
  }

  const cmputClaimCostUsdt = () => {
    const privateUsdt = eonPrivateAmount.div(decimalsToBig('1').div(privatePrice))
    console.log('privateUsdt == >', privateUsdt)
    const publicUsdt = eonPublicAmount.div(decimalsToBig('1').div(publicPrice))
    console.log('publicUsdt == >', publicUsdt)
    const totalUsdt = privateUsdt.add(publicUsdt)
    console.log('totalUsdt ==>', totalUsdt)
    return totalUsdt
  }

  useMemo(() => {
    cmputEONClaim()
    cmputUsdtClaim()
  }, [publicAmount, showBuy])

  const cmputIsPublicMin = () => {
    if (decimalsToBig(publicAmount).isZero()) {
      return false
      // if (decimalsToBig(publicAmount).lt(publicLimitMin)) {
      //   return false
      // } else {
      //   return true
      // }
    } else {
      return true
    }
  }
  const cmputIsPublicMax = () => {
    // if (eonPublicAmount.isZero()) {
    //   if (decimalsToBig(publicAmount).gt(publicLimitMax)) {
    //     return false
    //   } else {
    //     return true
    //   }
    // } else {
    //   const uPrice = decimalsToBig(decimalsToBig('1').div(publicPrice))
    //   const maxEdUsdt = decimalsToBig(eonPublicAmount.div(uPrice))
    //   const balUsdt = publicLimitMax.sub(maxEdUsdt)
    //   if (decimalsToBig(publicAmount).gt(balUsdt)) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
    return true
  }

  const navigate = useNavigate()
  const backHome = () => {
    navigate('/launchpad')
  }

  useEffect(() => {
    // if (chainId !== supportedChainId) {
    //   backHome()
    // }
    getPrivateInfo()
    getEthprice()
    getPendingEon()
    getUsdtBalance()
    getEthBalance()
    getpublicInfo()
    getEonPrivate()
    getEonPublic()
    getWithdrawed()
    initVerify()
    // getPublicLimit()
    getCurrentStageInfo()
    getInWhiteList()
    // console.log('xxxxxxxxx', decimalsToBig(formatEther(15000000000000000000 + '')))
  }, [provider, account, chainId, state, showBuy])
  useEffect(() => {
    initVerify()
  }, [publicAmount])

  const tokenList = [
    {
      value: 'usdt',
      label: 'USDT',
      icon: USDTIcon,
      addr: WOD_IDO_TOKEN_ADDRESS[chainId as any],
    },
    {
      value: 'eth',
      label: 'ETH',
      icon: ETHIcon,
      addr: WOD_ETH_TOKEN_ADDRESS[chainId as any],
    },
  ]
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    getEthprice()
    comptEon()
    setTokenType(value)
  }
  const comptUsdtMax = () => {
    return false
  }
  return (
    <LaunchpadCardComp>
      <GlobalStyle />
      <BuySuccessModal
        isOpen={showBuy}
        state={state}
        tokenType={tokenType}
        modalHeight={modalHeight}
        claimed={cmputEONClaim()}
        amount={cmputUsdtClaim()}
        onDismiss={handleDismissModal}
      ></BuySuccessModal>
      <ComeSoonModal
        isOpen={showCommSoon}
        modalHeight={modalHeight}
        onDismiss={handleDismissModal}
        endT={endTime}
        white={white}
      ></ComeSoonModal>
      <div className="state-box">
        <span>{stateStr[state]}</span>
      </div>
      <div className="top-eon">
        <div className="eon-logo">
          <img src={eonIdo} alt="" />
        </div>
        <div className="eon-links">
          <span>EON</span>
          <EonVedioList></EonVedioList>
        </div>
      </div>
      <TimerBox
        style={{
          borderWidth: state == 3 ? '0px' : '1px',
          marginBottom: state == 3 ? '0' : '24px',
        }}
      >
        <div className="state-time">
          {state == 0 && <span>Starts in</span>}
          {state == 1 && <span>Private sale ends in</span>}
          {state == 2 && <span>Public sale ends in</span>}
          {state == 4 && <span>Eon can be claimed in</span>}
          {state == 3 && <span>Eon can be claimed</span>}
        </div>
        {state != 3 && (
          <div className="count-down">
            <CountDown endT={endTime.toString()} init={() => getCurrentStageInfo}></CountDown>
          </div>
        )}
        {state == 3 && (
          <div className="eon-claim">
            <span>{formatEther(pendingEon).toString()} EON</span>
            {/* <span>{formatEther(eonPrivateAmount.add(eonPublicAmount).sub(withdrawedAmount).toString())}EON</span> */}
          </div>
        )}
        {state == 3 && (
          <div className="claim-box">
            {/* {eonPrivateAmount.add(eonPublicAmount).sub(withdrawedAmount).isZero() ? ( */}
            {pendingEon.isZero() ? (
              <div className="btn-default disabled">
                <span>Claim</span>
              </div>
            ) : (
              <>
                {claimLoading ? (
                  <div className={['btn-default', 'disabled'].join(' ')}>
                    <span>
                      <LoadingTrans>Claiming</LoadingTrans>
                    </span>
                  </div>
                ) : (
                  <div className="btn-default" onClick={claim}>
                    <span>Claim</span>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </TimerBox>
      {state != 3 && (
        <ProgressBox>
          <div className="progress-tit">
            <span>Progress ({pct}%)</span>
          </div>
          <Tooltip
            title={
              isShowSlider
                ? ''
                : `private sale ${Number(formatEther(maxprivateAmount.toString())).toLocaleString()} USDT`
            }
            placement="topLeft"
            // open={!isShowSlider}
          >
            <div className="progress-item">
              <Slider
                defaultValue={pct}
                value={pct}
                onChange={(value) => onChangePct(value)}
                tooltip={{ open: false }}
              />
              <Slider
                defaultValue={0}
                value={Number(formatEther(maxprivateAmount.toString()))}
                max={Number(formatEther(maxpublicAmount.toString()))}
                // onChange={(value) => onChangePct(value)}
                // tooltip={{ open: false }}
                tooltip={{
                  open: false,
                }}
              />
              {isShowSlider && (
                <Slider
                  defaultValue={0}
                  value={Number(formatEther(maxprivateAmount.toString()))}
                  max={Number(formatEther(maxpublicAmount.toString()))}
                  // onChange={(value) => onChangePct(value)}
                  tooltip={{
                    // open: true,
                    formatter: () =>
                      `private sale ${Number(formatEther(maxprivateAmount.toString())).toLocaleString()} USDT`,
                  }}
                />
              )}
            </div>
          </Tooltip>
          <div className="progress-amount">
            <span>
              {state == 1
                ? Number(formatEther(privatebuiedAmount.toString())).toLocaleString()
                : Number(formatEther(maxpublicAmount.toString())).toLocaleString()}{' '}
              USDT
            </span>
            <span
              style={{
                left: `${sliderPostion >= 10 ? sliderPostion : 20}%`,
              }}
            >
              {Number(formatEther(maxprivateAmount.toString())).toLocaleString()} USDT
            </span>
            <span>{Number(formatEther(maxpublicAmount.toString())).toLocaleString()} USDT</span>
          </div>
        </ProgressBox>
      )}
      {state != 4 && state != 3 && (
        <AmountBox>
          {(state == 0 || state == 1) && (
            <div className="private-amount">
              <div className="amount-tips">
                <Tooltip title="2400 EON per eligible address at the private sale stage">
                  <TipI></TipI>
                </Tooltip>
                <span>Amount</span>
              </div>
              <div className="amount-ut">
                <span>
                  {privateAmount.div(privatePrice).toString().toLocaleString()} EON (
                  {formatEther(privateAmount.toString()).toLocaleString()} USDT)
                </span>
              </div>
            </div>
          )}
          {state == 2 && (
            // <div className="public-amount">
            //   <div className="amount-tips">
            //     <Tooltip title={<PublicTitps min={publicLimitMin} max={publicLimitMax}></PublicTitps>}>
            //       <TipI></TipI>
            //     </Tooltip>
            //     <span>Amount</span>
            //   </div>
            //   <div className="input-box">
            //     <StyledNumericalInput
            //       className="token-amount-input"
            //       value={publicAmount}
            //       onUserInput={onUserInput}
            //       disabled={false}
            //       $loading={false}
            //       id="pubilc-amount"
            //       ref={inputPublicInputRef}
            //     />
            //     <span className="max" onClick={maxUsdt}>
            //       Max
            //     </span>
            //   </div>
            // </div>
            <div className="public-inputbox">
              <div className="title-amount">
                <span>Amount</span>
              </div>
              <div className="public-input">
                <div className="token-input">
                  <div className="select-box">
                    <img src={tokenList.filter((item: any) => item.value === tokenType)[0].icon} alt="" />
                    <Select defaultValue={tokenType} onChange={handleChange} suffixIcon={<SelectI />}>
                      {tokenList.map((item) => {
                        return (
                          <Option value={item.value} key={item.value}>
                            <img src={item.icon} alt="" />
                            <span>{item.label}</span>
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                  <div className="input-box">
                    <StyledNumericalInput
                      className="token-amount-input"
                      value={publicAmount}
                      onUserInput={onUserInput}
                      disabled={false}
                      $loading={false}
                      id="pubilc-amount"
                      ref={inputPublicInputRef}
                    />
                    <span>{tokenList.filter((item: any) => item.value === tokenType)[0].label}</span>
                  </div>
                </div>
                <div className="balance-box">
                  <span>
                    Balance:
                    {tokenType === 'eth'
                      ? formatEther(ethBalance).toLocaleString()
                      : formatEther(usdtBalance).toLocaleString()}
                  </span>
                  <span>{formatEther(comptEon()).toLocaleString()} EON</span>
                </div>
              </div>
            </div>
          )}
        </AmountBox>
      )}
      {state == 4 && (
        <>
          <ClaimedBox>
            <div className="private-amount">
              <div className="amount-tips">
                <span>Eon can be claimed</span>
              </div>
              <div className="amount-tips">
                <span>
                  {formatEther(eonPrivateAmount.add(eonPublicAmount).sub(withdrawedAmount).toString()).toLocaleString()}{' '}
                  EON
                </span>
                {/* <span>{formatEther(pendingEon).toString()} EON</span> */}
              </div>
            </div>
          </ClaimedBox>
          <ClaimedBox style={{ marginBottom: '0', border: 'none', paddingBottom: '0' }}>
            <div className="private-amount">
              <div className="amount-tips" style={{ gap: '6px' }}>
                <span>USDT cost</span>
                <Tooltip title="Your ETH cost has been converted to and included in the USDT cost at the rate of 1 ETH = 3538 USDT.">
                  <TipI></TipI>
                </Tooltip>
              </div>
              <div className="amount-tips">
                <span>{formatEther(cmputClaimCostUsdt()).toLocaleString()} USDT</span>
              </div>
            </div>
          </ClaimedBox>
        </>
      )}
      {state != 4 && state != 3 && (
        <BtnBox>
          {!account || !chainId || !provider ? (
            <div className="btn-default" onClick={toggleWalletDrawer}>
              <span>Please connect to your wallet</span>
            </div>
          ) : chainId && chainId !== supportedChainId ? (
            <div
              className="btn-default"
              onClick={async () => {
                try {
                  await switchChain(connector, 787878 as any)
                } catch (error) {
                  if (didUserReject(error)) {
                    // Ignore error, which keeps the user on the previous chain.
                  } else {
                    // TODO(WEB-3306): This UX could be improved to show an error state.
                    throw error
                  }
                }
              }}
            >
              <span>Connect to {getChainInfo(787878)?.label}</span>
            </div>
          ) : state == 1 && !white ? (
            <div className="btn-default disabled" onClick={() => setShowCommSoon(true)}>
              <span>You are not eligible for private sale</span>
            </div>
          ) : state == 1 && usdtBalance.lt(privateAmount) ? (
            <div className="btn-default disabled">
              <span>Insufficient USDT balance</span>
            </div>
          ) : state == 1 && !eonPrivateAmount.isZero() ? (
            <div className="btn-default disabled">
              <span>You’ve reached the maximum buy</span>
            </div>
          ) : state == 1 && privatebuiedAmount.eq(maxprivateAmount) ? (
            <div className="btn-default disabled">
              <span>Finished</span>
            </div>
          ) : state == 2 && usdtBalance.lt(publicLimitMin) ? (
            <div className="btn-default disabled">
              <span>Insufficient usdt amount</span>
            </div>
          ) : state == 2 && decimalsToBig(publicAmount).isZero() ? (
            <div className="btn-default disabled">
              <span>Please enter the amount</span>
            </div>
          ) : state == 2 && tokenType === 'usdt' && usdtBalance.lt(decimalsToBig(publicAmount)) ? (
            <div className="btn-default disabled">
              <span>Insufficient USDT balance</span>
            </div>
          ) : state == 2 && tokenType === 'eth' && ethBalance.lt(decimalsToBig(publicAmount)) ? (
            <div className="btn-default disabled">
              <span>Insufficient ETH balance</span>
            </div>
          ) : state == 2 && comptUsdtMax() ? (
            <div className="btn-default disabled">
              <span>Exceed the available amount.</span>
            </div>
          ) : state == 2 && !cmputIsPublicMin() ? (
            <div className="btn-default disabled">
              <span>Less than the minimum</span>
            </div>
          ) : state == 2 && !cmputIsPublicMax() ? (
            <div className="btn-default disabled">
              <span>Exceeding the maximum</span>
            </div>
          ) : state == 2 && publicbuiedAmount.eq(maxpublicAmount) ? (
            <div className="btn-default disabled">
              <span>Finished</span>
            </div>
          ) : state == 4 ? (
            <div className="btn-default disabled">
              <span>Finished</span>
            </div>
          ) : !approveToken ? (
            <>
              {approveLoading ? (
                <div className={['btn-default', 'disabled'].join(' ')}>
                  <span>
                    <LoadingTrans>Approving</LoadingTrans>
                  </span>
                </div>
              ) : (
                <div
                  className="btn-default"
                  onClick={() => approve(tokenList.filter((item: any) => item.value === tokenType)[0].addr)}
                >
                  <span>Approve {tokenList.filter((item: any) => item.value === tokenType)[0].label}</span>
                </div>
              )}
            </>
          ) : state == 1 && white ? (
            <>
              {buyLoading ? (
                <div className={['btn-default', 'disabled'].join(' ')}>
                  <span>
                    <LoadingTrans>Buying</LoadingTrans>
                  </span>
                </div>
              ) : (
                <div className="btn-default" onClick={privateAndPublicBuy}>
                  <span>Buy</span>
                </div>
              )}
            </>
          ) : state == 2 ? (
            <>
              {buyLoading ? (
                <div className={['btn-default', 'disabled'].join(' ')}>
                  <span>
                    <LoadingTrans>Buying</LoadingTrans>
                  </span>
                </div>
              ) : (
                <div className="btn-default" onClick={privateAndPublicBuy}>
                  <span>Buy</span>
                </div>
              )}
            </>
          ) : (
            <div className="btn-default disabled">
              <span>Buy</span>
            </div>
          )}
        </BtnBox>
      )}
    </LaunchpadCardComp>
  )
}
