import React from 'react'
import styled from 'styled-components'

import MyCollapse from './MyCollapse'

const InformationCardComp = styled.div`
  width: 100%;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);
  padding: 40px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 20px 10px;
  }
`
export default function InformationCard() {
  return (
    <InformationCardComp>
      <MyCollapse></MyCollapse>
    </InformationCardComp>
  )
}
