import React from 'react'
import Lottie from 'react-lottie'

type LottieViewerProps = {
  json: any
}
export default function LottieViewer(props: LottieViewerProps) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: props.json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Lottie options={defaultOptions} height="100%" width="100%" />
    </div>
  )
}
