// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BigNumber, ethers } from 'ethers'

// export const getCall = (address: string, name: string, abi: any, ...args: any) => {
//   // eslint-disable-next-line no-async-promise-executor
//   return new Promise(async (resolve, reject) => {
//     try {
//       // Connect to the network
//       const provider = new ethers.providers.Web3Provider(window.ethereum as any)
//       const contract = new ethers.Contract(address, abi, provider)
//       const res = await contract[name](...args)
//       return resolve({ code: 200, data: res })
//     } catch (error) {
//       console.log(error)
//       return reject({ code: 400, data: 0 })
//     }
//   })
// }

// 小数精度
// eslint-disable-next-line import/no-unused-modules
export const unifyNumber = (number: any, num: number) => {
  if (number === '') {
    return 0
  } else {
    const handleNum = parseFloat(number)
    const isToFixed = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > num
    if (isToFixed) {
      return handleNum.toFixed(num)
    } else {
      return handleNum
    }
  }
}

/**
 * 根据代币精度转换为小数
 * @param {BigNumber} n 大数
 * @param {*} d 代币精度 decimals
 * @param {*} c 保留小数位数
 * @returns
 */
export const formatUnitsDecimals = (n: any, d = 18, c = 18) => {
  let bn: any = ethers.utils.formatUnits(n, d)
  bn = unifyNumber(bn, c)
  return bn
}

// 按照精度将数字字符串 转换 bignumber
export const decimalsToBig = (n: any, d = 18) => {
  if (!n || n === '' || n === '.') return BigNumber.from('0')
  const decimalsV = ethers.utils.parseUnits(n + '', d)
  return decimalsV
}

// _hex 转换会 bignumber
export const hexToBig = (n: any) => {
  if (!n || n === '' || n === '.') return BigNumber.from('0')
  const hexV = BigNumber.from(n + '')
  return hexV
}

// 获取当前时间戳
export const getBlockTimestamp = async (provider: any) => {
  let timestamp: any = null
  // 获取最新的区块号
  await provider
    .getBlockNumber()
    .then(async (blockNumber: any) => {
      // 获取指定区块号的信息（这里以最新的区块为例）
      const block = await provider.getBlock(blockNumber)

      // 提取区块的时间戳
      timestamp = block.timestamp
    })
    .catch((err: any) => {
      console.log(err)
    })

  return timestamp
}
