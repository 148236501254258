import React from 'react'
import styled from 'styled-components'

import IdoImg from '../../assets/images/ido-bg.png'
import IdoHome from './IdoHome'

const LaunchpadPage = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding-top: 18px;
  }
`
const LaunchpadMain = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 160px;
  .img-box {
    overflow: hidden;
    width: 1094px;
    height: 704px;
    position: relative;

    img {
      position: absolute;
      right: 0px;
      height: 100%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xxl}px) {
    gap: 0px;
    .img-box {
      height: 650px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 12px;
    justify-content: center;
    .img-box {
      display: none;
    }
  }
`
export default function Launchpad() {
  return (
    <LaunchpadPage>
      <LaunchpadMain>
        <div className="img-box">
          <img src={IdoImg} alt="" />
        </div>
        <IdoHome></IdoHome>
      </LaunchpadMain>
    </LaunchpadPage>
  )
}
