import React from 'react'
import styled from 'styled-components'

import IdoImg from '../../assets/images/ido-view-bg.webp'
import InformationCard from './InformationCard'
import LaunchpadCard from './LaunchpadCard'

const LaunchpadDetails = styled.div`
  width: 100%;
  /* height: 100vh; */
  .bg-box {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    /* left: 0; */
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .main-box {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
    gap: 16px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      padding-top: 0;
    }
    .main-card,
    .information-card {
      width: 800px;
      @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
        width: 100%;
        max-width: 100%;
        padding: 12px;
        padding-top: 0px;
        overflow: hidden;
      }
    }
  }
`
export default function index() {
  return (
    <LaunchpadDetails>
      <div className="bg-box">
        <img src={IdoImg} alt="" />
      </div>
      <div className="main-box">
        <div className="main-card">
          <LaunchpadCard></LaunchpadCard>
        </div>
        <div className="information-card">
          <InformationCard></InformationCard>
        </div>
      </div>
    </LaunchpadDetails>
  )
}
