import { Currency } from '@uniswap/sdk-core'
import { WodToken } from 'constants/wodList'

interface CurrencyInt {
  token0: WodToken
  token1: WodToken
}

interface ValueInt {
  value0: string
  value1: string
}

interface BalanceInt {
  token0: string
  token1: string
}

interface MmethodsName {
  approve?: boolean
  addLiquidity?: boolean
  swap?: boolean
}
interface CurrencyToken {
  token0: Currency | null
  token1: Currency | null
}

// eslint-disable-next-line import/no-unused-modules
export interface LiquidityState {
  readonly value: ValueInt
  readonly token: CurrencyInt
  readonly balance: BalanceInt
  readonly pending: MmethodsName
  readonly currency: CurrencyToken
}

const initialState: LiquidityState = {
  value: {
    value0: '',
    value1: '',
  },
  token: {
    token0: {
      chainId: 0,
      decimals: 0,
      symbol: '',
      name: '',
    },
    token1: {
      chainId: 0,
      decimals: 0,
      symbol: '',
      name: '',
    },
  },
  balance: {
    token0: '',
    token1: '',
  },
  pending: {
    approve: false,
    addLiquidity: false,
    swap: false,
  },
  currency: {
    token0: null,
    token1: null,
  },
}

const liquidity_reducer = (state: LiquidityState = initialState, action: any) => {
  switch (action.type) {
    case 'SET_VALUE':
      // const newState: ReduxState = JSON.parse(JSON.stringify(state));
      // newState.address = action.address
      // eslint-disable-next-line no-case-declarations
      const newValue: LiquidityState = {
        ...state,
      }
      newValue.value[action.valueType as keyof ValueInt] = action.value
      return newValue
    case 'SET_TOKEN':
      // eslint-disable-next-line no-case-declarations
      // const newTokenA: LiquidityState = JSON.parse(JSON.stringify(state))
      // newTokenA.token[action.tokenType as keyof CurrencyInt] = action.token
      // eslint-disable-next-line no-case-declarations
      const newToken: LiquidityState = {
        ...state,
        token: {
          token0: state.token.token0,
          token1: state.token.token1,
        },
      }
      newToken.token[action.tokenType as keyof CurrencyInt] = action.token
      return newToken
    case 'SET_CURRENCY': {
      const newCurrency: LiquidityState = {
        ...state,
        currency: {
          token0: state.currency.token0,
          token1: state.currency.token1,
        },
      }
      newCurrency.currency[action.currencyType as keyof CurrencyToken] = action.token
      return newCurrency
    }
    case 'SET_BALANCE':
      // eslint-disable-next-line no-case-declarations
      const newBalance: LiquidityState = {
        ...state,
        balance: {
          token0: state.balance.token0,
          token1: state.balance.token1,
        },
      }
      newBalance.balance[action.balanceType as keyof BalanceInt] = action.balance
      return newBalance
    case 'SET_PENDING': {
      const newPending: LiquidityState = {
        ...state,
        pending: {
          approve: state.pending.approve,
          addLiquidity: state.pending.addLiquidity,
          swap: state.pending.swap,
        },
      }
      newPending.pending[action.name as keyof MmethodsName] = action.pending
      return newPending
    }
    default:
      return state
  }
}

export default liquidity_reducer
