// eslint-disable-next-line import/no-unused-modules
export const DEFAULT_ERC20_DECIMALS = 18

export interface WodToken {
  chainId: number
  address?: string
  decimals: number
  symbol: string
  name: string
  logoURI?: string
  disabled?: boolean
}

export const isSupplyChain = (chainId: number | undefined) => {
  return chainId && SupplyChain.includes(chainId as number)
}

// eslint-disable-next-line import/no-unused-modules
export const SupplyChain = [787878]

export const getChainList = (chainId: number | undefined) => {
  let list: Array<WodToken> = []
  let supplyList: Array<WodToken> = []
  switch (chainId) {
    case 787878:
      list = DEFAULT_WOD_LIST
      supplyList = DEFAULT_WOD_LIST
      break
    default:
      break
  }
  return { list, supplyList }
}

// eslint-disable-next-line import/no-unused-modules
export const WOD_ETH: WodToken = {
  chainId: 787878,
  decimals: 18,
  address: '0x0000000000000000000000000000000000000000',
  logoURI: 'https://explorer.wodrpc.org/favicon.ico',
  symbol: 'WOD',
  name: 'WOD',
}

// eslint-disable-next-line import/no-unused-modules
const list = 'https://wodswap.io/tokenlist.json'
// eslint-disable-next-line import/no-unused-modules
export const WOD_LIST: any = {
  tokens: [
    // {
    //   chainId: 818181,
    //   address: '0xB8205eBD246eD05C4CBB5323c9f8748Ff375Ec45',
    //   logoURI: 'https://explorer.wodrpc.org/favicon.ico',
    //   decimals: 18,
    //   symbol: 'WWOD',
    //   name: 'Wrapped WOD',
    // },
    {
      chainId: 818181,
      address: '0xB9A3F8AD52572FBC02683d504cb700F5596eb46E',
      // logoURI: 'https://dapp.wfirst.info/nft.jpg',
      decimals: 18,
      symbol: 'TE1',
      name: 'TEST Token1',
    },
    {
      chainId: 818181,
      address: '0x2Ed747a71A741782bb17f109B9bE80633b61E84f',
      // logoURI: 'https://dapp.wfirst.info/nft.jpg',
      decimals: 18,
      symbol: 'TE2',
      name: 'TEST Token2',
    },
    {
      chainId: 818181,
      address: '0xb10b2F138766aCAEa00CE1d5A2ad4448d24c08F1',
      // logoURI: 'https://dapp.wfirst.info/nft.jpg',
      decimals: 18,
      symbol: 'TEU',
      name: 'TEST USDT',
    },
    {
      chainId: 787878,
      symbol: 'USDT',
      name: 'USDT',
      address: '0x4ca9f32fd6aba6e8662786b0f4371d6d3cc468ee',
      decimals: 18,
      logoURI: 'https://wodswap.io/usdt.png',
    },
  ],
}

export enum SwapMethod {
  TOKEN_TOKEN = 'swapExactTokensForTokensSupportingFeeOnTransferTokens', // TOKEN=>TOKEN
  WOD_TOKEN = 'swapExactETHForTokensSupportingFeeOnTransferTokens', // WOD=>TOKEN
  TOKEN_WOD = 'swapExactTokensForETHSupportingFeeOnTransferTokens', // WOD=>TOKEN
}

// eslint-disable-next-line import/no-unused-modules
export const WOD_TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in number]?: string } } = {
  USDT: {
    818181: '0xb10b2F138766aCAEa00CE1d5A2ad4448d24c08F1',
  },
}
// eslint-disable-next-line import/no-unused-modules
export const DEFAULT_WOD_LIST: Array<WodToken> = [WOD_ETH, ...WOD_LIST.tokens]
