import { Currency } from '@uniswap/sdk-core'
import { WodToken } from 'constants/wodList'

export const SetValue = (valueType: string, value: string) => {
  return { type: 'SET_VALUE', value, valueType }
}

export const SetToken = (tokenType: string, token: WodToken) => {
  return { type: 'SET_TOKEN', token, tokenType }
}

export const SetCurrency = (currencyType: string, token: Currency) => {
  return { type: 'SET_CURRENCY', token, currencyType }
}

export const SetBalance = (balanceType: string, balance: string) => {
  return { type: 'SET_BALANCE', balance, balanceType }
}

export const SetPending = (name: string, pending: any) => {
  return { type: 'SET_PENDING', name, pending }
}
