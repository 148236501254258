import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const CountDownComm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 46px;

  .dt {
    font-size: 56px;
    font-weight: 700;
    color: #fff;
    margin-top: -20px;
    font-family: 'SF Pro';
  }
  .day,
  .hour,
  .min,
  .sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    > span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;
    }
    .time-li {
      display: flex;
      width: 100px;
      height: 100px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: linear-gradient(180deg, #202120 0%, #161715 100%);
      position: relative;
      overflow: hidden;
      span {
        font-size: 56px;
        font-weight: 700;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background: #000;
        box-shadow: 0 1px 0px 1px #272827;
      }
      @media screen and (max-width: ${({ theme }) => theme.breakpoint.xxl}px) {
        width: 80px;
        height: 80px;
      }
      @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
        width: 48px;
        height: 48px;
        span {
          font-size: 28px;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xxl}px) {
    gap: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    gap: 12px;
    .dt {
      font-size: 28px;
    }
  }
`

type CountDownProps = {
  endT: number | string
  init: () => void
}

let timer: any = null
export default function CountDown(props: CountDownProps) {
  // const [timer, setTimer]: any = useState(null)
  const { account, provider, chainId, connector } = useWeb3React()
  const [days, setDays]: any = useState(0)
  const [hours, setHours]: any = useState(0)
  const [minutes, setMinutes]: any = useState(0)
  const [seconds, setSeconds]: any = useState(0)

  const countdowm = async () => {
    // console.log(props.endT)
    if (!props.endT || props.endT == 0) return
    clearInterval(timer)
    // setTimer(
    // const times = await getBlockTimestamp(provider)
    timer = setInterval(async () => {
      // now
      const times = new Date().getTime()
      // const times = await getBlockTimestamp(provider)
      const t = Number(props.endT) * 1000 - times
      // console.log('t ==>', t, times)
      if (t > 0) {
        let day: any = Math.floor(t / 86400000)
        let hour: any = Math.floor((t / 3600000) % 24)
        let min: any = Math.floor((t / 60000) % 60)
        let sec: any = Math.floor((t / 1000) % 60)
        day = day < 10 ? '0' + day : day
        hour = hour < 10 ? '0' + hour : hour
        min = min < 10 ? '0' + min : min
        sec = sec < 10 ? '0' + sec : sec

        setDays(day)
        setHours(hour)
        setMinutes(min)
        setSeconds(sec)
      } else {
        clearInterval(timer)
        props.init()
        // setDays('0')
        // setHours('0')
        // setMinutes('0')
        // setSeconds('0')
      }
    }, 1000)
    // )
  }

  useEffect(() => {
    countdowm()
    return () => {
      clearInterval(timer)
    }
  }, [props.endT])
  return (
    <CountDownComm>
      <div className="day">
        <div className="time-li">
          <span>{days}</span>
        </div>
        <span>DAYS</span>
      </div>
      <span className="dt">:</span>
      <div className="hour">
        <div className="time-li">
          <span>{hours}</span>
        </div>
        <span>HRS</span>
      </div>
      <span className="dt">:</span>
      <div className="min">
        <div className="time-li">
          <span>{minutes}</span>
        </div>
        <span>MINS</span>
      </div>
      <span className="dt">:</span>
      <div className="sec">
        <div className="time-li">
          <span>{seconds}</span>
        </div>
        <span>SECS</span>
      </div>
    </CountDownComm>
  )
}
