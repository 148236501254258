// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ethers } from 'ethers'

// 小数精度
// eslint-disable-next-line import/no-unused-modules
export const unifyNumber = (number: any, num: number) => {
  if (number === '') {
    return 0
  } else {
    const handleNum = parseFloat(number)
    const isToFixed = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > num
    if (isToFixed) {
      return handleNum.toFixed(num)
    } else {
      return handleNum
    }
  }
}

export const formatEther = (n: any, c = 6) => {
  if (n) {
    const num: string = Number(ethers.utils.formatEther(n.toString())).toString()
    const fine_num: number = Math.floor(Number(num) * 10 ** c) / 10 ** c
    return parseFloat(fine_num + '')
  } else {
    return 0
  }
}

// 简化地址
export const accountFormat = (account: string, s = 6, e = 3, line = '...') => {
  return `${account.substring(0, s)}${line}${account.substring(account.length - e, account.length)}`
}
