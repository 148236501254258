import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
import SwapBuyFiatButton from './SwapBuyFiatButton'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 23px;
  color: ${({ theme }) => theme.neutral2};
`

const HeaderButtonContainer = styled(RowFixed)`
  padding: 0 0px;
  gap: 16px;
`
const TabsBefore = styled(RowFixed)`
  padding-bottom: 15px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 3px;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.neutral4};
    bottom: 0;
    left: 50%;
  }
`

export default function SwapHeader({
  autoSlippage,
  chainId,
  trade,
}: {
  autoSlippage: Percent
  chainId?: number
  trade?: InterfaceTrade
}) {
  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeaderBold>
          <Trans>
            <TabsBefore>Swap</TabsBefore>
          </Trans>
        </ThemedText.SubHeaderBold>
        <SwapBuyFiatButton />
      </HeaderButtonContainer>
      <RowFixed>
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} trade={trade} />
      </RowFixed>
    </StyledSwapHeader>
  )
}
