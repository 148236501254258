import { useWeb3React } from '@web3-react/core'
import type { CollapseProps } from 'antd'
import { Collapse, theme } from 'antd'
import useCopyClipboard from 'hooks/useCopyClipboard'
import type { CSSProperties } from 'react'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { ReactComponent as CopyI } from '../../assets/svg/copy-icon.svg'
import { ReactComponent as DownI } from '../../assets/svg/down-icon.svg'
import { ReactComponent as InfoI } from '../../assets/svg/information-icon.svg'

const InformationListComp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .info-item {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .left {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
    }
    .right {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
    }
    .copy-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      span {
        display: inline-block;
        width: 100%;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap !important;
      }
      svg {
        cursor: pointer;
      }
    }
  }
  & .info-item:last-of-type {
    padding: 0;
    border: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    .info-item {
      .left {
        font-size: 10px;
      }
      .right {
        font-size: 10px;
      }
      .copy-item {
        gap: 4px;
        text-align: right;
      }
    }
  }
`
const InformationList = () => {
  const { account, provider, chainId } = useWeb3React()
  const [, setCopied] = useCopyClipboard()
  const list = [
    // {
    //   id: 1,
    //   label: 'Token Address',
    //   value: WOD_IDO_ADDRESS[chainId as any],
    // },
    {
      id: 2,
      label: 'Qualified whitelists',
      value: '800',
    },
    {
      id: 3,
      label: 'Total Supply',
      value: '4,320,000 EON',
    },
    {
      id: 4,
      label: 'Tokens For Private Sale',
      value: '1,920,000 EON',
    },
    {
      id: 5,
      label: 'Tokens For Public Sale',
      value: '2,400,000 EON',
    },
    {
      id: 6,
      label: 'Private Sale Rate',
      value: '1 USDT = 24 EON',
    },
    {
      id: 7,
      label: 'Public Sale Rate',
      value: '1 USDT = 20 EON; 1 ETH = 70,760 EON',
    },
    {
      id: 8,
      label: 'Hard Cap',
      value: '200,000 USDT',
    },
    {
      id: 9,
      label: 'Private sale Start Time',
      value: '2024.6.16 3:00 (UTC)',
    },
    {
      id: 10,
      label: 'Private sale End Time',
      value: '2024.6.16 7:00 (UTC)',
    },
    {
      id: 11,
      label: 'Public sale Start Time',
      value: '2024.6.16 7:00 (UTC)',
    },
    {
      id: 12,
      label: 'Public sale End Time',
      value: '2024.6.19 16:00 (UTC)',
    },
    {
      id: 13,
      label: 'Release time',
      value: '2024.6.20 7:00 (UTC)',
    },
    {
      id: 14,
      label: 'Unlock',
      value: 'Unlock 50% at release time, 6 months linear release the remaining',
    },
  ]
  const copy = useCallback(() => {
    setCopied('0x883cFA5ec14cB7B93Dd7118Cd54e9bfBf0B81edb' ?? '')
  }, [setCopied])
  return (
    <InformationListComp>
      {list.map((item) => {
        return (
          <div className="info-item" key={item.id}>
            <span className="left">{item.label}</span>
            <div className={['right', item.id == 1 && 'copy-item'].join(' ')}>
              <span>{item.value}</span>
              {item.id == 1 && <CopyI onClick={copy}></CopyI>}
            </div>
          </div>
        )
      })}
    </InformationListComp>
  )
}

const LabelItemComp = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }
`
interface LabelItemProps {
  label: string
}
const LabelItem = ({ label }: LabelItemProps) => {
  return (
    <LabelItemComp>
      <InfoI></InfoI>
      <span>{label}</span>
    </LabelItemComp>
  )
}

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
  {
    key: '1',
    label: <LabelItem label="Information"></LabelItem>,
    children: <InformationList></InformationList>,
    style: panelStyle,
  },
]

const MyCollapseComp = styled.div`
  width: 100%;
  .ant-collapse {
    background: transparent !important;
    & .ant-collapse-item:last-of-type {
      margin: 0 !important;
    }
    .ant-collapse-item {
      background: transparent !important;
      .ant-collapse-header {
        padding: 0;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 40px 0 0;
          @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
            padding: 20px 0 0;
          }
        }
      }
    }
  }
`
export default function MyCollapse() {
  const { token } = theme.useToken()

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  }

  return (
    <MyCollapseComp>
      <Collapse
        bordered={false}
        defaultActiveKey={[]}
        expandIconPosition="end"
        expandIcon={({ isActive }) => {
          return <DownI style={{ rotate: isActive ? '180deg' : '0deg', transition: 'all .2s' }} />
        }}
        style={{ background: token.colorBgContainer }}
        items={getItems(panelStyle)}
      />
    </MyCollapseComp>
  )
}
