import LottieViewer from 'components/Lottie/LottieViewer'
import Modal from 'components/Modal'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BigNumber } from 'ethers'
import React from 'react'
import styled from 'styled-components'
import { formatEther } from 'utils/ethersUtils'

import { ReactComponent as CloseI } from '../../assets/svg/close.svg'
import successJson from '../../components/Lottie/json/success-lottie.json'

interface BuySuccessModalProps {
  isOpen: boolean
  tokenType: string
  modalHeight?: number
  onDismiss: () => void
  claimed?: BigNumber
  amount?: BigNumber
  state: any
}
const BuySuccessModalCmpt = styled.div`
  background-color: #000;
  position: relative;
  /* @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 100%;
  } */
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .buy-type,
  .claim-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px 40px;
    .ani-box {
      width: 150px;
      height: 150px;
      margin-bottom: 8px;
    }
    .txt-content {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 24px;
    }
    .list-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
`
export default function BuySuccessModal(props: BuySuccessModalProps) {
  // const { account, provider, chainId } = useWeb3React()
  // const [success, setSuccess] = useState(false)
  // useEffect(() => {
  //   setSuccess(true)
  // }, [])
  const close = () => {
    // setSuccess(false)
    props.onDismiss()
  }
  return (
    <Modal isOpen={props.isOpen} maxWidth={634} minHeight={props.modalHeight} onDismiss={close}>
      <BuySuccessModalCmpt>
        <div className="close-icon" onClick={close}>
          <CloseI></CloseI>
        </div>
        {(props.state == 1 || props.state == 2) && (
          <div className="buy-type">
            <div className="ani-box">
              <LottieViewer json={successJson}></LottieViewer>
            </div>
            <span className="txt-content">Buy-in EON successfully!</span>
            <div className="list-box">
              <div className="list-item">
                <span>Eon can be claimed</span>
                <span>{formatEther(props.claimed?.toString())}EON</span>
              </div>
              <div className="list-item">
                <span>{props.tokenType.toUpperCase()} cost</span>
                <span>
                  {formatEther(props.amount?.toString())} {props.tokenType.toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        )}
        {props.state == 3 && (
          <div className="claim-type">
            <div className="ani-box">
              <LottieViewer json={successJson}></LottieViewer>
            </div>
            <span className="txt-content">Claim EON successfully!</span>
            <div className="list-box">
              <div className="list-item">
                <span>Eon claimed</span>
                <span>{formatEther(props.claimed?.toString())}EON</span>
              </div>
            </div>
          </div>
        )}
      </BuySuccessModalCmpt>
    </Modal>
  )
}
