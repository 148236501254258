import { asSupportedChain } from './chains'

export const WOD_V2_ROUTER_ADDRESS = '0x463739903a10A455B307BA6A91bc64aF51c6465e'

export const WOD_V2_FACTORY_ADDRESSES: { [key: number]: string } = {
  [1]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  [5]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  [787878]: '0x9F324Dcfa59Fe283eD85C3Ca0108A5565fb6C965',
}

export const WOD_MULTICALL_ADDRESSES: { [key: number]: string } = {
  1: '0x1F98415757620B543A52E61c46B32eB19261F984',
  5: '0x1F98415757620B543A52E61c46B32eB19261F984',
  787878: '0x144c0B0Ccd153fa9690A4Aba99a94764e6B3df0e',
}

export const WOD_INIT_CODE_HASH: { [key: number]: string } = {
  1: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
  5: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
  787878: '0xcc90dadc05a6ec4d326c232a1fb97a64febf64c9d73d1a02e9b8170a26243caf',
}

export const WOD_IDO_ADDRESS: { [key: number]: string } = {
  787878: '0x2530000b2ffa2916911367f6add1005159e0e888', // 正式: 0x2530000b2ffa2916911367f6add1005159e0e888 测试: 0x4493d20aa2e23b45efd98c5335fabc169adc8041
}
export const WOD_IDO_TOKEN_ADDRESS: { [key: number]: string } = {
  787878: '0x4cA9F32fD6aBA6e8662786B0f4371d6d3cc468EE', // 正式: 0x4cA9F32fD6aBA6e8662786B0f4371d6d3cc468EE 测试: 0xa791431495dd9DcF6Df58B7B353cC5Befe656cF7
}

export const WOD_ETH_TOKEN_ADDRESS: { [key: number]: string } = {
  787878: '0x3C5956f39696f0d52ED05955368f5Bfd7a1e1159', // 正式: 0x3C5956f39696f0d52ED05955368f5Bfd7a1e1159 测试: 0x1C4Ce48fEA3986916136D66FEFD312dF783AfBC1
}

const IDO_SUPPLY_CHAIN = [787878]

export const isIdoSupportedChain = (chainid: any) => {
  const supportedChainId = asSupportedChain(chainid)
  return supportedChainId && IDO_SUPPLY_CHAIN.indexOf(chainid) != -1 ? chainid : undefined
}
