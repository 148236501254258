import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { Trans } from '@lingui/macro'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { ChainId, Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import IUniswapV2PairJSON from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useWeb3React } from '@web3-react/core'
import ERC20_ABI from 'abis/erc20.json'
import TROST_FACTORY from 'abis/TrustFactory.json'
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { GrayCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { AutoRow } from 'components/Row'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import { ArrowWrapper, PageWrapper, SwapWrapper } from 'components/swap/styled'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import SwapHeader from 'components/swap/SwapHeader'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { useConnectionReady } from 'connection/eagerlyConnect'
import { getChainInfo } from 'constants/chainInfo'
import { asSupportedChain, isSupportedChain } from 'constants/chains'
import { ZERO_ADDRESS } from 'constants/misc'
import { getSwapCurrencyId, TOKEN_SHORTHANDS, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { WOD_V2_FACTORY_ADDRESSES } from 'constants/wodContract'
import { SwapMethod } from 'constants/wodList'
import { useUniswapXDefaultEnabled } from 'featureFlags/flags/uniswapXDefault'
import { useCurrency, useDefaultActiveTokens } from 'hooks/Tokens'
import { useWodV2RouterContract } from 'hooks/useContract'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import { useMaxAmountIn } from 'hooks/useMaxAmountIn'
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { SwapResult, useSwapCallback } from 'hooks/useSwapCallback'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { useUSDPrice } from 'hooks/useUSDPrice'
import useWrapCallback, { WrapErrorText, WrapType } from 'hooks/useWrapCallback'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { ReactNode, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import { SetPending } from 'state/liquidity/actions'
import store from 'state/liquidity/store'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { isClassicTrade, isPreviewTrade } from 'state/routing/utils'
import { Field, forceExactInput, replaceSwapState } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionInfo, TransactionType } from 'state/transactions/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components'
import { LinkStyledButton, ThemedText } from 'theme/components'
import { maybeLogFirstSwapAction } from 'tracing/swapFlowLoggers'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { decimalsToBig, formatUnitsDecimals, getBlockTimestamp } from 'utils/commContract'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { NumberType, useFormatter } from 'utils/formatNumbers'
import { getContract } from 'utils/getContract'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from 'utils/prices'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import { useScreenSize } from '../../hooks/useScreenSize'
import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { OutputTaxTooltipBody } from './TaxTooltipBody'
import { UniswapXOptIn } from './UniswapXOptIn'

export const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  background-color: ${({ theme }) => theme.surface1};
  border-radius: 10px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  font-weight: 500;
  min-height: 120px;
  line-height: 20px;
  padding: 16px;
  position: relative;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 2px solid transparent;
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.surface1}`};
`

const LoadingTrans = styled.div`
  position: relative;
  text-align: left;
  &::after {
    display: block;
    content: '. . .';
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    transform: translateX(120%);
    animation: pendingKey 1s infinite linear;
  }
  @keyframes pendingKey {
    0% {
      content: '.';
    }
    50% {
      content: '. .';
    }
    100% {
      content: '. . .';
    }
  }
`
function getIsReviewableQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  if (swapInputError) return false
  // if the current quote is a preview quote, allow the user to progress to the Swap review screen
  if (isPreviewTrade(trade)) return true

  return Boolean(trade && tradeState === TradeState.VALID)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const PAIR_INTERFACE = new Interface(IUniswapV2PairJSON.abi)

export default function SwapPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  const location = useLocation()

  const supportedChainId = asSupportedChain(connectedChainId)

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <PageWrapper>
        <Swap
          className={className}
          chainId={supportedChainId ?? (787878 as any)}
          initialInputCurrencyId={loadedUrlParams?.[Field.INPUT]?.currencyId}
          initialOutputCurrencyId={loadedUrlParams?.[Field.OUTPUT]?.currencyId}
          disableTokenInputs={supportedChainId === undefined}
        />
        {/* <NetworkAlert /> */}
      </PageWrapper>
      {location.pathname === '/swap' && <SwitchLocaleLink />}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  initialInputCurrencyId,
  initialOutputCurrencyId,
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  initialInputCurrencyId?: string | null
  initialOutputCurrencyId?: string | null
  chainId?: ChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const trace = useTrace()

  // token warning stuff
  const prefilledInputCurrency = useCurrency(initialInputCurrencyId)
  const prefilledOutputCurrency = useCurrency(initialOutputCurrencyId)

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(prefilledInputCurrency)
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(prefilledOutputCurrency)
  // console.log(prefilledInputCurrency, prefilledOutputCurrency)
  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency)
    setLoadedOutputCurrency(prefilledOutputCurrency)
  }, [prefilledInputCurrency, prefilledOutputCurrency])

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId)
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = asSupportedChain(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // swap state
  const prefilledState = useMemo(
    () => ({
      [Field.INPUT]: { currencyId: initialInputCurrencyId },
      [Field.OUTPUT]: { currencyId: initialOutputCurrencyId },
    }),
    [initialInputCurrencyId, initialOutputCurrencyId]
  )
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        swapResult: undefined,
      })
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const swapInfo = useDerivedSwapInfo(state, chainId)
  // console.log('swapInfo', swapInfo)
  const {
    trade: { state: tradeState, trade, swapQuoteLatency },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    inputTax,
    outputTax,
  } = swapInfo

  const [inputTokenHasTax, outputTokenHasTax] = useMemo(
    () => [!inputTax.equalTo(0), !outputTax.equalTo(0)],
    [inputTax, outputTax]
  )

  useEffect(() => {
    // Force exact input if the user switches to an output token with tax
    if (outputTokenHasTax && independentField === Field.OUTPUT) dispatch(forceExactInput())
  }, [independentField, outputTokenHasTax, trade?.outputAmount])

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.postTaxOutputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])
  const getSingleUnitAmount = (currency?: Currency) => {
    if (!currency) return
    return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(10 ** currency.decimals))
  }

  const fiatValueInput = useUSDPrice(
    parsedAmounts[Field.INPUT] ?? getSingleUnitAmount(currencies[Field.INPUT]),
    currencies[Field.INPUT]
  )
  const fiatValueOutput = useUSDPrice(
    parsedAmounts[Field.OUTPUT] ?? getSingleUnitAmount(currencies[Field.OUTPUT]),
    currencies[Field.OUTPUT]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      tradeState === TradeState.NO_ROUTE_FOUND,
      tradeState === TradeState.LOADING,
      tradeState === TradeState.LOADING && Boolean(trade),
    ],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.postTaxOutputAmount)
  const preTaxFiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const [stablecoinPriceImpact, preTaxStablecoinPriceImpact] = useMemo(
    () =>
      routeIsSyncing || !isClassicTrade(trade)
        ? [undefined, undefined]
        : [
            computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
            computeFiatValuePriceImpact(fiatValueTradeInput.data, preTaxFiatValueTradeOutput.data),
          ],
    [fiatValueTradeInput, fiatValueTradeOutput, preTaxFiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      console.log('input', value)
      onUserInput(Field.INPUT, value)
      // dispatch(typeInput({ field: Field.OUTPUT, typedValue: '1000' }))
      maybeLogFirstSwapAction(trace)
    },
    [onUserInput, trace]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      maybeLogFirstSwapAction(trace)
    },
    [onUserInput, trace]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, swapResult }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: InterfaceTrade
    swapError?: Error
    swapResult?: SwapResult
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    swapError: undefined,
    swapResult: undefined,
  })

  const { formatCurrencyAmount } = useFormatter()
  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount({
            amount: parsedAmounts[dependentField],
            type: NumberType.SwapTradeAmount,
            placeholder: '',
          }),
    }),
    [dependentField, formatCurrencyAmount, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage)
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    // isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined,
    trade?.fillType
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const swapCallback = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const handleContinueToReview = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      swapResult: undefined,
    })
  }, [trade])

  const clearSwapState = useCallback(() => {
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      swapResult: undefined,
    }))
  }, [])

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (preTaxStablecoinPriceImpact && !confirmPriceImpactWithoutFee(preTaxStablecoinPriceImpact)) {
      return
    }
    swapCallback()
      .then((result) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          swapResult: result,
        }))
      })
      .catch((error) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          swapResult: undefined,
        }))
      })
  }, [swapCallback, preTaxStablecoinPriceImpact])

  const handleOnWrap = useCallback(async () => {
    if (!onWrap) return
    try {
      const txHash = await onWrap()
      setSwapState((currentState) => ({
        ...currentState,
        swapError: undefined,
        txHash,
      }))
      onUserInput(Field.INPUT, '')
    } catch (error) {
      if (!didUserReject(error)) {
        sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
          wrapType,
          input: currencies[Field.INPUT],
          output: currencies[Field.OUTPUT],
        })
      }
      console.error('Could not wrap/unwrap', error)
      setSwapState((currentState) => ({
        ...currentState,
        swapError: error,
        txHash: undefined,
      }))
    }
  }, [currencies, onUserInput, onWrap, wrapType])

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    if (!isClassicTrade(trade)) {
      return { priceImpactSeverity: 0, largerPriceImpact: undefined }
    }

    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, preTaxStablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [preTaxStablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    // If there was a swap, we want to clear the input
    if (swapResult) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, swapResult])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, tradeToConfirm: trade }))
  }, [trade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, state, trace]
  )
  const inputCurrencyNumericalInputRef = useRef<HTMLInputElement>(null)

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, state, trace]
  )

  const showPriceImpactWarning = isClassicTrade(trade) && largerPriceImpact && priceImpactSeverity > 3

  const prevTrade = usePrevious(trade)
  useEffect(() => {
    if (!trade || prevTrade === trade) return // no new swap quote to log

    sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_RECEIVED, {
      ...formatSwapQuoteReceivedEventProperties(trade, allowedSlippage, swapQuoteLatency, inputTax, outputTax),
      ...trace,
    })
  }, [prevTrade, trade, trace, allowedSlippage, swapQuoteLatency, inputTax, outputTax])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const inputCurrency = currencies[Field.INPUT] ?? undefined
  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const showOptInSmall = !useScreenSize().navSearchInputVisible
  const isDark = useIsDarkMode()
  const isUniswapXDefaultEnabled = useUniswapXDefaultEnabled()

  const TitLineBottom = styled.div`
    padding-bottom: 13px;
    margin-bottom: 13px;
    border-bottom: 1px solid #101014;
  `

  const [valueIn, setValueIn] = useState('')
  const [valueOut, setValueOut] = useState('')
  const [isPair, setIsPair] = useState(false)
  const [reserves, setReserves] = useState<any>(null)
  const [currencyAddrA, setCurrency0] = useState('')
  const [currencyAddrB, setCurrency1] = useState('')
  const [currencyIn, setCurrencyIn] = useState(null)
  const [currencyOut, setCurrencyOut] = useState(null)
  const [path, setPath] = useState<string[]>([])
  const [method, setMethod] = useState('')
  const [isWarp, setIsWarp] = useState(false)
  const [isApprove, setIsApprove] = useState(false)
  const [isBalPair, setIsBalPair] = useState(true)
  const [swapPostion, setSwapPostion] = useState(false)

  const verifyApprove = async (token: any, amount: string) => {
    if (!token || !chainId || !provider || !account) return
    const address = token.symbol === 'WOD' ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : token.address
    if (token.address !== ZERO_ADDRESS) {
      const allowanceBal: BigNumber = await getAllowance(address)
      console.log('allowanceBal ==>', allowanceBal.toString())
      const decimals = token.decimals
      // console.log('value', amount)
      const value0 = decimalsToBig(amount, decimals)
      const isPass = allowanceBal.lt(value0)
      setIsApprove(isPass)
      // console.log('is approve ==>', isPass)
    }
  }
  const cmptTypeIntput = useCallback(
    async (value: string) => {
      try {
        if (!chainId) return
        setValueIn(value)
        if (isWarp) {
          setValueOut(value)
          return handleTypeInput(value)
        }
        if (currencies[Field.INPUT]?.symbol != 'WOD') {
          // console.log('xxxxxxxxxxxxx', currencies[Field.INPUT]?.address, value)
          verifyApprove(currencies[Field.INPUT], value)
        }
        setPath([currencyAddrA, currencyAddrB])
        // console.log('xxxxxxxxxxxx', isPair)
        if (isPair) {
          if (!value || value === '') return setValueOut('')
          if (
            !currencies[Field.INPUT] ||
            !currencies[Field.OUTPUT] ||
            !currencyAddrA ||
            !currencyAddrB ||
            !currencies ||
            !reserves
          )
            return
          const addressA =
            currencies[Field.INPUT].symbol === 'WOD'
              ? WRAPPED_NATIVE_CURRENCY[chainId]?.address
              : (currencies[Field.INPUT] as Token).address
          const addressB =
            currencies[Field.OUTPUT].symbol === 'WOD'
              ? WRAPPED_NATIVE_CURRENCY[chainId]?.address
              : (currencies[Field.OUTPUT] as Token).address
          const { reserve0, reserve1 } = reserves
          let computValue1 = BigNumber.from(0)
          if (!addressA || !addressB) return
          if (addressA.toLowerCase() < addressB.toLowerCase()) {
            const mulK = decimalsToBig(value, (currencies[Field.INPUT] as any).decimals).mul(reserve1)
            computValue1 = mulK.div(decimalsToBig(value, (currencies[Field.INPUT] as any).decimals).add(reserve0))

            if (reserve0.lt(decimalsToBig(value, (currencies[Field.INPUT] as any).decimals))) return setIsBalPair(false)
            setIsBalPair(true)
          } else {
            const mulK = decimalsToBig(value, (currencies[Field.INPUT] as any).decimals).mul(reserve0)
            computValue1 = mulK.div(reserve1.add(decimalsToBig(value, (currencies[Field.INPUT] as any).decimals)))
            // const mulV = reserve0.mul(reserve1)
            // console.log(
            //   reserve0.add(decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals)).toString(),
            //   mulV.div(reserve0.add(decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals))).toString(),
            //   reserve1
            //     .sub(mulV.div(reserve0.add(decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals))))
            //     .toString()
            // )
            if (reserve1.lt(decimalsToBig(value, (currencies[Field.INPUT] as any).decimals))) return setIsBalPair(false)
            setIsBalPair(true)
          }
          // const price = reserve0.div(reserve1)
          // const computValue1 =
          //   currencyAddrA.toLowerCase() < currencyAddrB.toLowerCase()
          //     ? decimalsToBig(value, (currencies[Field.INPUT] as any).decimals)
          //         .div(price)
          //         .toString()
          //     : decimalsToBig(value, (currencies[Field.INPUT] as any).decimals)
          //         .mul(price)
          //         .toString()
          setValueOut(formatUnitsDecimals(computValue1, (currencies[Field.INPUT] as any).decimals))
        }
      } catch (error) {
        setValueIn('')
        setValueOut('')
      }
    },
    [isPair, reserves, currencyAddrA, valueIn, currencyAddrB, isWarp, currencies, handleTypeInput]
  )
  const cmptTypeOutput = useCallback(
    (value: string) => {
      try {
        setValueOut(value)
        if (isWarp) {
          setValueIn(value)
          return handleTypeOutput(value)
        }
        if (currencies[Field.INPUT]?.symbol != 'WOD') {
          // console.log('xxxxxxxxxxxxx', currencies[Field.OUTPUT], value)
          verifyApprove(currencies[Field.INPUT], value)
        }
        setPath([currencyAddrA, currencyAddrB])
        if (isPair) {
          if (!value || value === '') return setValueIn('')
          if (!currencyAddrA || !currencyAddrB || !currencies || !reserves) return
          const { reserve0, reserve1 } = reserves
          let computValue0 = BigNumber.from(0)
          if (currencyAddrA.toLowerCase() < currencyAddrB.toLowerCase()) {
            const mulK = decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals).mul(reserve0)
            computValue0 = mulK.div(reserve1.add(decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals)))

            if (reserve1.lt(decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals)))
              return setIsBalPair(false)
            setIsBalPair(true)
          } else {
            const mulK = decimalsToBig(value, (currencies[Field.INPUT] as any).decimals).mul(reserve1)
            computValue0 = mulK.div(decimalsToBig(value, (currencies[Field.INPUT] as any).decimals).add(reserve0))

            if (reserve0.lt(decimalsToBig(value, (currencies[Field.OUTPUT] as any).decimals)))
              return setIsBalPair(false)
            setIsBalPair(true)
          }
          // const price = reserve0.div(reserve1)
          // const computValue0 =
          //   currencyAddrA.toLowerCase() < currencyAddrB.toLowerCase()
          //     ? decimalsToBig(value, (currencies[Field.INPUT] as any).decimals)
          //         .mul(price)
          //         .toString()
          //     : decimalsToBig(value, (currencies[Field.INPUT] as any).decimals)
          //         .div(price)
          //         .toString()
          setValueIn(formatUnitsDecimals(computValue0, (currencies[Field.INPUT] as any).decimals))
        }
      } catch (error) {
        setValueIn('')
        setValueOut('')
      }
    },
    [isPair, reserves, handleTypeOutput, valueOut, isWarp, currencyAddrA, currencyAddrB, currencies]
  )

  const handleMaxInput = useCallback(async () => {
    // maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    if (!maxInputAmount || !chainId) return
    // onUserInput(Field.INPUT, maxInputAmount.toExact())
    setValueIn(maxInputAmount.toExact())
    cmptTypeIntput(maxInputAmount.toExact())
    // const addressA =
    //   (currencies[Field.INPUT] as Token).symbol === 'WOD'
    //     ? WRAPPED_NATIVE_CURRENCY[chainId]?.address
    //     : (currencies[Field.INPUT] as Token).address
    // const addressB =
    //   (currencies[Field.OUTPUT] as Token).symbol === 'WOD'
    //     ? WRAPPED_NATIVE_CURRENCY[chainId]?.address
    //     : (currencies[Field.OUTPUT] as Token).address
    // console.log(addressA, addressB)
    // if (!addressA || !addressB) return
    // if (addressA.toLowerCase() < addressB.toLowerCase()) {
    // }

    maybeLogFirstSwapAction(trace)
  }, [maxInputAmount, onUserInput, trace, isPair])

  const { provider } = useWeb3React()
  const getPair = async (currency0: any, currency1: any) => {
    setValueIn('')
    setValueOut('')
    try {
      if (!currency0 || !currency1 || !chainId || !provider) return
      const addressA = currency0.symbol === 'WOD' ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : currency0.address
      const addressB = currency1.symbol === 'WOD' ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : currency1.address
      // console.log(currency0, currency1)
      if (
        (currency0.symbol === 'WOD' || currency0.symbol === 'WWOD') &&
        (currency1.symbol === 'WOD' || currency1.symbol === 'WWOD')
      ) {
        setIsWarp(true)
      } else {
        setIsWarp(false)
      }
      setCurrency0(addressA)
      setCurrency1(addressB)
      // console.log('address ==>', addressA, addressB)
      const contract = getContract(WOD_V2_FACTORY_ADDRESSES[chainId], TROST_FACTORY, provider)
      const pair = await contract.getPair(addressA, addressB)
      if (currency0.symbol === 'WOD' && currency1.symbol !== 'WOD') {
        setMethod(SwapMethod.WOD_TOKEN)
      } else if (currency0.symbol !== 'WOD' && currency1.symbol === 'WOD') {
        setMethod(SwapMethod.TOKEN_WOD)
      } else {
        setMethod(SwapMethod.TOKEN_TOKEN)
      }
      if (pair !== ZERO_ADDRESS) {
        // 存在 pair
        const pairContract = getContract(pair, PAIR_INTERFACE, provider)
        const reserves = await pairContract.getReserves()
        setReserves(reserves)
        if (
          reserves.reserve0.lt(decimalsToBig(valueOut == '' ? 1 : valueOut, (currencies[Field.OUTPUT] as any).decimals))
        ) {
          setValueIn('')
          setValueOut('')
          setIsPair(false)
        } else {
          setIsPair(true)
        }
        setPath([addressA, addressB])
        console.log(
          'reserves ==>',
          pair,
          currency0.name,
          currency1.name,
          reserves.reserve0.toString(),
          reserves.reserve1.toString()
        )
      } else {
        setIsPair(false)
        setValueIn('')
        setValueOut('')
        // const pairUsdA = await contract.getPair(addressA, WOD_TOKEN_SHORTHANDS.USDT[chainId])
        // const pairUsdB = await contract.getPair(addressB, WOD_TOKEN_SHORTHANDS.USDT[chainId])
        // if (pairUsdA === ZERO_ADDRESS && pairUsdB === ZERO_ADDRESS) {
        //   setIsPair(false)
        // } else {
        //   setIsPair(true)
        //   const routeAddr = pairUsdA === ZERO_ADDRESS ? pairUsdB : pairUsdA
        //   setPath([addressA, routeAddr, addressB])
        // }
      }
    } catch (error) {
      setIsPair(false)
      setValueIn('')
      setValueOut('')
    }
  }
  const [isChangePrice, setIsChangePrice] = useState(false)
  const priceChange = async () => {
    //
  }
  const [pairLoadng, setPairLoading] = useState(false)
  useEffect(() => {
    const updData = async () => {
      setPairLoading(true)
      // console.log('xxxx')
      await getPair(currencies[Field.INPUT], currencies[Field.OUTPUT])
      // console.log('====', isPair)
      setPairLoading(false)
    }
    updData()
  }, [isPair, currencies, provider, swapPostion, currencies[Field.INPUT], currencies[Field.OUTPUT]])

  const router = useWodV2RouterContract()
  const getAllowance = async (address: string): Promise<BigNumber> => {
    if (!address || !provider || !account) return BigNumber.from(0)
    const contract = getContract(address, ERC20_ABI, provider, account)
    const approveBalance: BigNumber = await contract.allowance(account, router?.address)
    return approveBalance
  }

  const approve = async () => {
    console.log('approve')

    try {
      store.dispatch(SetPending('approve', true))
      const approveToken: any = currencies[Field.INPUT]
      if (!approveToken?.address || !provider || !account) return
      const tokenContract = getContract(approveToken?.address, ERC20_ABI, provider, account)

      const estimatedGas = await tokenContract.estimateGas.approve(router?.address, MaxUint256).catch((error) => {
        console.log(error)
        return tokenContract.estimateGas.approve(router?.address, MaxUint256)
      })
      // console.log('estimatedGas ===>', estimatedGas, router?.address)
      tokenContract
        .approve(router?.address, MaxUint256, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        .then((response: any) => {
          provider.once(response, async (tx) => {
            setIsApprove(true)
            store.dispatch(SetPending('approve', false))
            verifyApprove(currencies[Field.INPUT], valueIn)
          })
          // addTransaction(response, {
          //   type: TransactionType.SWAP,
          //   baseCurrencyId: currencyId(currencies[Field.INPUT]),
          //   quoteCurrencyId: currencyId(currencies[Field.OUTPUT]),
          //   expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? '0',
          //   expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? '0',
          // })
          const transactionInfo: TransactionInfo = {
            type: TransactionType.APPROVAL,
            tokenAddress: approveToken?.address,
            spender: router?.address as string,
            amount: valueIn,
          }

          addTransaction(response, transactionInfo)

          const eventProperties = {
            chain_id: chainId,
            token_symbol: approveToken?.symbol,
            token_address: approveToken?.address,
          }
          sendAnalyticsEvent(InterfaceEventName.APPROVE_TOKEN_TXN_SUBMITTED, eventProperties)
        })
        .catch((error: Error) => {
          store.dispatch(SetPending('approve', false))
          throw error
        })
    } catch (error) {
      store.dispatch(SetPending('approve', false))
    }
  }
  const ttl = useAppSelector((state) => state.user.userDeadline)
  const analyticsContext = useTrace()
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const addTransaction = useTransactionAdder()
  const onSwap = async () => {
    console.log('swap')
    if (!chainId) return
    // const transactionInfo: TransactionInfo = {
    //   type: TransactionType.SWAP,
    //   inputCurrencyId: currencies[Field.INPUT]?.isNative
    //     ? (WRAPPED_NATIVE_CURRENCY[chainId]?.address as string)
    //     : ((currencies[Field.INPUT] as Token).address as string),
    //   outputCurrencyId: currencies[Field.OUTPUT]?.isNative
    //     ? (WRAPPED_NATIVE_CURRENCY[chainId]?.address as string)
    //     : ((currencies[Field.OUTPUT] as Token).address as string),
    //   isUniswapXOrder: false,
    //   tradeType: TradeType.EXACT_INPUT,
    //   inputCurrencyAmountRaw: decimalsToBig(valueIn, (currencies[Field.INPUT] as any).decimals).toString(),
    //   expectedOutputCurrencyAmountRaw: decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals).toString(),
    //   minimumOutputCurrencyAmountRaw: decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals)
    //     .sub(0)
    //     .toString(),
    // }
    // console.log(transactionInfo)
    // addTransaction(
    //   { hash: '0xc0d1222040d1c1c513f5af6390d6a2bb6885ca5ba5fdbe32f9e4ccaac01a047d' } as TransactionResponse,
    //   transactionInfo
    // )
    try {
      await priceChange()
      if (isChangePrice) return
      if (!router || !provider) return
      const blockTimestamp = await getBlockTimestamp(provider)
      let params: any[] = []
      let value: any = null
      const point = userSlippageTolerance === 'auto' ? 5 : (userSlippageTolerance as any).toFixed(2)
      const subAmount =
        point === 0
          ? 0
          : decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals)
              .mul(decimalsToBig(point, 2))
              .div(10000)

      if (method === SwapMethod.TOKEN_TOKEN) {
        params = [
          decimalsToBig(valueIn, (currencies[Field.INPUT] as any).decimals),
          decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals).sub(subAmount),
          path,
          account,
          blockTimestamp + ttl,
        ]
        value = null
        console.log('params TOKEN_TOKEN==>', params, params[0].toString(), params[1].toString(), subAmount.toString())
      } else if (method === SwapMethod.WOD_TOKEN) {
        params = [
          decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals).sub(subAmount),
          path,
          account,
          blockTimestamp + ttl,
        ]
        value = decimalsToBig(valueIn)
        console.log('params WOD_TOKEN ==> ', params, params[0].toString())
      } else if (method === SwapMethod.TOKEN_WOD) {
        params = [
          decimalsToBig(valueIn, (currencies[Field.INPUT] as any).decimals),
          decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals).sub(subAmount),
          path,
          account,
          blockTimestamp + ttl,
        ]
        value = null
        console.log('params TOKEN_WOD==>', params, params[0].toString(), params[1].toString())
      }

      const methodFuc = router[method]

      const estimate = router.estimateGas[method]
      await estimate(...params, value ? { value } : {})
        .then((estimatedGasLimit) =>
          methodFuc(...params, {
            ...(value ? { value } : {}),
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response: any) => {
            console.log(response)
            store.dispatch(SetPending('swap', true))
            provider.once(response, async (tx: any) => {
              store.dispatch(SetPending('swap', false))
              setValueIn('')
              setValueOut('')
            })
            const transactionInfo: TransactionInfo = {
              type: TransactionType.SWAP,
              inputCurrencyId: currencies[Field.INPUT]?.isNative
                ? (WRAPPED_NATIVE_CURRENCY[chainId]?.address as string)
                : ((currencies[Field.INPUT] as Token).address as string),
              outputCurrencyId: currencies[Field.OUTPUT]?.isNative
                ? (WRAPPED_NATIVE_CURRENCY[chainId]?.address as string)
                : ((currencies[Field.OUTPUT] as Token).address as string),
              isUniswapXOrder: false,
              tradeType: TradeType.EXACT_INPUT,
              inputCurrencyAmountRaw: decimalsToBig(valueIn, (currencies[Field.INPUT] as any).decimals).toString(),
              expectedOutputCurrencyAmountRaw: decimalsToBig(
                valueOut,
                (currencies[Field.OUTPUT] as any).decimals
              ).toString(),
              minimumOutputCurrencyAmountRaw: decimalsToBig(valueOut, (currencies[Field.OUTPUT] as any).decimals)
                .sub(subAmount)
                .toString(),
            }

            addTransaction(response, transactionInfo)

            // sendAnalyticsEvent(SwapEventName.SWAP_TRANSACTION_COMPLETED, {
            //   // We only log the time-to-swap metric for the first swap of a session,
            //   // so if it was previously set we log undefined here.
            //   time_to_swap: hasSetSwapSuccess ? undefined : elapsedTime,
            //   time_to_swap_since_first_input: hasSetSwapSuccess
            //     ? undefined
            //     : timestampTracker.getElapsedTime(SwapEventType.FIRST_SWAP_SUCCESS, SwapEventType.FIRST_SWAP_ACTION),
            //   hash,
            //   chainId,
            //   ...analyticsContext,
            // })

            // const performanceMetrics = {
            //   // We only log the time_to_first_quote_request metric for the first quote request of a session.
            //   time_to_first_quote_request: '1',
            //   time_to_first_quote_request_since_first_input: '1',
            // }
            // sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_FETCH, {
            //   chainId,
            //   isQuickRoute: false,
            //   ...performanceMetrics,
            // })
            // logSwapSuccess(response.hash, chainId as number, analyticsContext)
          })
        )
        .catch((error: Error) => {
          store.dispatch(SetPending('swap', false))
          // notification.error({ message: error.message })
          console.log(error.message)
        })
    } catch (error) {
      // notification.error({ message: error.message })
      console.log(error)
      store.dispatch(SetPending('swap', false))
    }
  }

  const toBridge = () => {
    window.open('https://bridge.wodrpc.org/#/index')
  }

  const swapElement = (
    <SwapWrapper isDark={isDark} className={className} id="swap-page">
      <TokenSafetyModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokenAddress={importTokensNotInDefault[0]?.address}
        secondTokenAddress={importTokensNotInDefault[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel={true}
      />
      <SwapHeader trade={trade} autoSlippage={autoSlippage} chainId={chainId} />
      {trade && showConfirm && (
        <ConfirmSwapModal
          trade={trade}
          inputCurrency={inputCurrency}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          onCurrencySelection={onCurrencySelection}
          swapResult={swapResult}
          allowedSlippage={allowedSlippage}
          clearSwapState={clearSwapState}
          onConfirm={handleSwap}
          allowance={allowance}
          swapError={swapError}
          onDismiss={handleConfirmDismiss}
          fiatValueInput={fiatValueTradeInput}
          fiatValueOutput={fiatValueTradeOutput}
        />
      )}
      {showPriceImpactModal && showPriceImpactWarning && (
        <PriceImpactModal
          priceImpact={largerPriceImpact}
          onDismiss={() => setShowPriceImpactModal(false)}
          onContinue={() => {
            setShowPriceImpactModal(false)
            handleContinueToReview()
          }}
        />
      )}
      <div style={{ display: 'relative' }}>
        <SwapSection>
          <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
            <SwapCurrencyInputPanel
              label={
                <Trans>
                  <TitLineBottom>You pay</TitLineBottom>
                </Trans>
              }
              disabled={disableTokenInputs || pairLoadng}
              // value={formattedAmounts[Field.INPUT]}
              value={valueIn}
              showMaxButton={showMaxButton}
              currency={currencies[Field.INPUT] ?? null}
              // onUserInput={handleTypeInput}
              onUserInput={cmptTypeIntput}
              onMax={handleMaxInput}
              fiatValue={showFiatValueInput ? fiatValueInput : undefined}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              showCommonBases
              id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
              loading={independentField === Field.OUTPUT && routeIsSyncing}
              ref={inputCurrencyNumericalInputRef}
            />
          </Trace>
        </SwapSection>
        <ArrowWrapper clickable={isSupportedChain(chainId)}>
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SwapEventName.SWAP_TOKENS_REVERSED}
            element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
          >
            <ArrowContainer
              data-testid="swap-currency-button"
              onClick={() => {
                if (disableTokenInputs) return
                onSwitchTokens(inputTokenHasTax, formattedAmounts[dependentField])
                maybeLogFirstSwapAction(trace)
                setSwapPostion(!swapPostion)
                // setValueIn(valueIn != valueOut ? valueOut : valueIn)
                // setValueOut(valueOut != valueIn ? valueIn : valueOut)
                // cmptTypeIntput(valueIn != valueOut ? valueOut : valueIn)
                // cmptTypeOutput(valueOut != valueIn ? valueIn : valueOut)
              }}
              color={theme.neutral1}
            >
              <ArrowDown size="16" color={theme.neutral1} />
            </ArrowContainer>
          </TraceEvent>
        </ArrowWrapper>
      </div>
      <AutoColumn gap="xs">
        <div>
          <OutputSwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
              <SwapCurrencyInputPanel
                // value={formattedAmounts[Field.OUTPUT]}
                value={valueOut}
                disabled={disableTokenInputs || pairLoadng}
                // onUserInput={handleTypeOutput}
                onUserInput={cmptTypeOutput}
                label={
                  <Trans>
                    <TitLineBottom>You receive</TitLineBottom>
                  </Trans>
                }
                showMaxButton={false}
                hideBalance={false}
                fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                priceImpact={stablecoinPriceImpact}
                currency={currencies[Field.OUTPUT] ?? null}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                loading={independentField === Field.INPUT && routeIsSyncing}
                numericalInputSettings={{
                  // We disable numerical input here if the selected token has tax, since we cannot guarantee exact_outputs for FOT tokens
                  disabled: outputTokenHasTax,
                  // Focus the input currency panel if the user tries to type into the disabled output currency panel
                  onDisabledClick: () => inputCurrencyNumericalInputRef.current?.focus(),
                  disabledTooltipBody: <OutputTaxTooltipBody currencySymbol={currencies[Field.OUTPUT]?.symbol} />,
                }}
              />
            </Trace>
            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.neutral2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    <Trans>- Remove recipient</Trans>
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}
          </OutputSwapSection>
        </div>
        {false && (
          <>
            {showDetailsDropdown && (
              <SwapDetailsDropdown
                trade={trade}
                syncing={routeIsSyncing}
                loading={routeIsLoading}
                allowedSlippage={allowedSlippage}
              />
            )}
          </>
        )}
        {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
        <div style={{ marginTop: '26px' }}>
          {
            swapIsUnsupported ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Unsupported asset</Trans>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : switchingChain ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
              </ButtonPrimary>
            ) : connectionReady && !account ? (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: getIsReviewableQuote(trade, tradeState, swapInputError) }}
                element={InterfaceElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonLight onClick={toggleWalletDrawer} fontWeight={535} $borderRadius="16px">
                  <Trans>Connect wallet</Trans>
                </ButtonLight>
              </TraceEvent>
            ) : chainId && chainId !== connectedChainId ? (
              <ButtonPrimary
                $borderRadius="16px"
                onClick={async () => {
                  try {
                    await switchChain(connector, chainId)
                  } catch (error) {
                    if (didUserReject(error)) {
                      // Ignore error, which keeps the user on the previous chain.
                    } else {
                      // TODO(WEB-3306): This UX could be improved to show an error state.
                      throw error
                    }
                  }
                }}
              >
                Connect to {getChainInfo(chainId)?.label}
              </ButtonPrimary>
            ) : showWrap ? (
              <ButtonPrimary
                $borderRadius="16px"
                disabled={Boolean(wrapInputError)}
                onClick={handleOnWrap}
                fontWeight={535}
                data-testid="wrap-button"
              >
                {wrapInputError ? (
                  <WrapErrorText wrapInputError={wrapInputError} />
                ) : wrapType === WrapType.WRAP ? (
                  <Trans>Wrap</Trans>
                ) : wrapType === WrapType.UNWRAP ? (
                  <Trans>Unwrap</Trans>
                ) : null}
              </ButtonPrimary>
            ) : // : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
            //   <GrayCard style={{ textAlign: 'center' }}>
            //     <ThemedText.DeprecatedMain mb="4px">
            //       <Trans>Insufficient liquidity for this trade.</Trans>
            //     </ThemedText.DeprecatedMain>
            //   </GrayCard>
            // )
            !currencies[Field.INPUT] || !currencies[Field.OUTPUT] ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Select a token</Trans>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : !isPair ? (
              <GrayCard style={{ textAlign: 'center' }}>
                <ThemedText.DeprecatedMain mb="4px">Insufficient liquidity for this trade</ThemedText.DeprecatedMain>
              </GrayCard>
            ) : !valueIn || !valueOut ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Enter an amount</Trans>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : !isBalPair ? (
              <GrayCard style={{ textAlign: 'center' }}>
                <ThemedText.DeprecatedMain mb="4px">Insufficient liquidity for this trade</ThemedText.DeprecatedMain>
              </GrayCard>
            ) : isApprove ? (
              <ButtonPrimary $borderRadius="16px" disabled={store.getState().pending.approve} onClick={approve}>
                <Trans>{store.getState().pending.approve ? <LoadingTrans>Approve</LoadingTrans> : 'Approve'}</Trans>
              </ButtonPrimary>
            ) : (
              <ButtonPrimary $borderRadius="16px" disabled={store.getState().pending.swap} onClick={onSwap}>
                <Trans>{store.getState().pending.swap ? <LoadingTrans>Swap</LoadingTrans> : 'Swap'}</Trans>
              </ButtonPrimary>
            )
            // : (
            //   <TraceEvent
            //     events={[BrowserEvent.onClick]}
            //     name={SharedEventName.ELEMENT_CLICKED}
            //     element={InterfaceElementName.SWAP_BUTTON}
            //   >
            //     <ButtonError
            //       onClick={() => {
            //         showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
            //       }}
            //       id="swap-button"
            //       data-testid="swap-button"
            //       disabled={!getIsReviewableQuote(trade, tradeState, swapInputError)}
            //       error={!swapInputError && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
            //     >
            //       <Text fontSize={20}>
            //         {swapInputError ? (
            //           swapInputError
            //         ) : routeIsSyncing || routeIsLoading ? (
            //           <Trans>Swap</Trans>
            //         ) : priceImpactSeverity > 2 ? (
            //           <Trans>Swap anyway</Trans>
            //         ) : (
            //           <Trans>Swap</Trans>
            //         )}
            //       </Text>
            //     </ButtonError>
            //   </TraceEvent>
            //   )
          }
          {account && (
            <ButtonPrimary
              $borderRadius="16px"
              onClick={toBridge}
              style={{ marginTop: '12px', backgroundColor: '#003D39', color: '#ACFFFA' }}
            >
              Bridge Your Tokens
            </ButtonPrimary>
          )}
        </div>
      </AutoColumn>
      {!showOptInSmall && !isUniswapXDefaultEnabled && <UniswapXOptIn isSmall={false} swapInfo={swapInfo} />}
    </SwapWrapper>
  )

  return (
    <>
      {swapElement}
      {showOptInSmall && !isUniswapXDefaultEnabled && <UniswapXOptIn isSmall swapInfo={swapInfo} />}
    </>
  )
}
